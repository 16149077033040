import React from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { MdReply } from "react-icons/md";
import { GET } from "../../../../Auxiliar/Requests";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NAME, URL, URLadmin } from "../../../../variables";
import { maskCep, maskCnpj } from "../../../../Auxiliar/Masks";
import { FaTrash } from "react-icons/fa";
import SweetAlert from "react-bootstrap-sweetalert";
import { Step, StepLabel, Stepper } from "@mui/material";
import ConfirmOrganizationModal from "../../../Modais/ConfirmOrganizationModal";
import { NumericFormat } from "react-number-format";
import "../../../Cadastros/PontosColeta/css/style.css";

const ConvidarOrganizacao = () => {
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = React.useState(1);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [loadingSave, setLoadingSave] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [data, setData] = React.useState({
    commercial_name: "",
    company_name: "",
    cnpj: "",
    cep: "",
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    city: "",
    state: "",
  });
  const [form, setForm] = React.useState({
    responsible_id: "",
    responsible_name: "",
    responsible_email: "",
    authorized_person: {},

    max_capacity_weight: "",
    unity_weight_id: "",
    max_capacity_volume: "",
    unity_volume_id: "",
  });
  const [
    internalRelationshipWasteRejects,
    setInternalRelationshipWasteRejects,
  ] = React.useState([]);
  const [organization, setOrganization] = React.useState({});

  const [wasteRejects, setWasteRejects] = React.useState([]);
  const [weightUnities, setWeightUnities] = React.useState([]);
  const [volumeUnities, setVolumeUnities] = React.useState([]);

  const user = useSelector((state) => state.AppReducer.user);

  React.useEffect(() => {
    getRR();
    getUnities();
    getData();
  }, []);

  //-------------------------*-------------------------
  const getData = async () => {
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/show/${params.id}?connection=${NAME}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    )
      .then(async (responseLog) => {
        try {
          let response = await responseLog.json();
          if (response.response) {
            setInternalRelationshipWasteRejects(
              response?.internalRelationship.wasteRejects
            );
            setData((prevData) => ({
              ...prevData,
              company_name:
                response.internalRelationship.organization.linked.company_name,
              commercial_name:
                response.internalRelationship.organization.linked
                  .commercial_name,
              cnpj: response.internalRelationship.organization.linked.cnpj,
              cep: response.internalRelationship.organization.linked.cep,
              street: response.internalRelationship.organization.linked.street,
              neighborhood:
                response.internalRelationship.organization.linked.nbhd,
              city: response.internalRelationship.organization.linked.city,
              state: response.internalRelationship.organization.linked.state,
              number: response.internalRelationship.organization.linked.number,
              comp: response.internalRelationship.organization.linked.comp,
            }));
            setOrganization(response.internalRelationship.organization.logged);
          } else {
            history.push("/relacionamentos_internos");
          }
        } catch (err) {
          history.push("/relacionamentos_internos");
          console.error(err);
        }
      })
      .catch((err) => {
        history.push("/relacionamentos_internos");
        console.error(err);
      });
  };

  const getRR = async () => {
    const response = await GET({ url: `index_destino` });
    if (response.residuos_rejeitos) {
      setWasteRejects(response.residuos_rejeitos);
    }
  };

  const getUnities = async () => {
    const response = await GET({ url: `get_dados_ponto` });
    if (response.unidades_peso && response.unidades_volume) {
      setWeightUnities(response.unidades_peso);
      setVolumeUnities(response.unidades_volume);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  //* WASTE REJECT HANDLER */
  function handleWasteRejectSelector(value, index) {
    let updatedWRs = [...internalRelationshipWasteRejects];
    if (value === "") {
      updatedWRs[index].destination = {
        id: "",
        name: "",
      };
    } else {
      let getResidueReject = wasteRejects.filter((item) => item.id === value);
      updatedWRs[index].destination = {
        id: getResidueReject[0].id,
        name: getResidueReject[0].nome,
      };
    }

    setInternalRelationshipWasteRejects(updatedWRs);
    return;
  }

  //* EXPEDIENT HANDLER */
  const [expedient, setExpedient] = React.useState({
    weekdays: {
      dom: false,
      seg: false,
      ter: false,
      qua: false,
      qui: false,
      sex: false,
      sab: false,
    },
    openingHours: [],
    errorMessage: "",

    openingTime: "",
    closingTime: "",
  });

  const toggleDay = (day) => {
    setExpedient((prevExpedient) => ({
      ...prevExpedient,
      weekdays: {
        ...prevExpedient.weekdays,
        [day]: !prevExpedient.weekdays[day],
      },
    }));
  };

  const removeExpedientItem = (index) => {
    setExpedient((prevExpedient) => ({
      ...prevExpedient,
      openingHours: prevExpedient.openingHours.filter((_, i) => i !== index),
    }));
  };

  const handleInsertExpedient = () => {
    const { openingTime, closingTime, weekdays, openingHours } = expedient;

    // Verificar se os horários de abertura e fechamento estão preenchidos
    if (!openingTime || !closingTime) {
      setExpedient((prevExpedient) => ({
        ...prevExpedient,
        errorMessage: "Preencha todos os campos",
      }));
      return;
    }

    // Verificar se o horário de abertura é anterior ao horário de fechamento
    if (openingTime >= closingTime) {
      setExpedient((prevExpedient) => ({
        ...prevExpedient,
        errorMessage: "Horários conflitantes",
      }));
      return;
    }

    // Verificar se pelo menos um dia da semana foi selecionado
    const selectedDays = Object.keys(weekdays).filter((day) => weekdays[day]);
    if (selectedDays.length === 0) {
      setExpedient((prevExpedient) => ({
        ...prevExpedient,
        errorMessage: "Escolha pelo menos um dia da semana",
      }));
      return;
    }

    // Construir o objeto de horário de abertura
    const newOpeningHours = {
      day: selectedDays,
      openingTime,
      closingTime,
    };

    // Verificar conflitos de horários
    for (let i = 0; i < openingHours.length; i++) {
      const currentOpeningHour = openingHours[i];
      for (let j = 0; j < currentOpeningHour.day.length; j++) {
        const currentDay = currentOpeningHour.day[j];
        if (selectedDays.includes(currentDay)) {
          if (
            currentOpeningHour.openingTime < closingTime &&
            openingTime < currentOpeningHour.closingTime
          ) {
            setExpedient((prevExpedient) => ({
              ...prevExpedient,
              errorMessage: "Horários conflitantes",
            }));
            return;
          }
        }
      }
    }

    // Atualizar os horários de abertura
    setExpedient((prevExpedient) => ({
      ...prevExpedient,
      openingHours: [...prevExpedient.openingHours, newOpeningHours],
      openingTime: "", // Resetar os campos do formulário
      closingTime: "", // Resetar os campos do formulário
      errorMessage: "", // Limpar a mensagem de erro
    }));
  };
  //* HANDLE SUBMIT */
  const acceptInvitation = () => {
    if (page === 1) {
      setPage(2);
      return;
    }

    let formData = { ...form };
    formData.authorized_person = {
      id: user.id,
      name: user.name,
      email: user.email,
    };

    formData.waste_rejects = internalRelationshipWasteRejects;

    // //* validating waste rejects
    let wasteRejectsAreSync = internalRelationshipWasteRejects.map((item) => {
      if (item.destination.id === "") {
        return false;
      }
      return true;
    });

    if (!wasteRejectsAreSync.every((value) => value === true)) {
      setErrorMsg(
        "Faça o vínculo de todos os resíduos e rejeitos para continuar."
      );
      window.scrollTo(500, 0);
      return;
    }

    //sync expedient
    let openingHours = [...expedient.openingHours];
    let workExpedient = [];
    openingHours.forEach((element) => {
      element.day.forEach((day) => {
        let obj = {
          weekday: day,
          opening_time: element.openingTime,
          closing_time: element.closingTime,
        };
        workExpedient.push(obj);
      });
    });
    formData.expedient = workExpedient;

    setErrorMsg(null);
    setLoadingSave(true);

    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/accept/${params.id}`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    ).then(async (responseLog) => {
      try {
        let response = await responseLog.json();
        if (response.response && response.data) {
          setLoadingSave(false);
          setShowAlert(true);
        } else {
          if (response.message === "validation_error") {
            setErrorMsg("Preencha os campos corretamente");
          } else {
            setErrorMsg("Erro ao pegar resposta do servidor.");
          }
          setLoadingSave(false);
          window.scrollTo(500, 0);
        }
        return;
      } catch (err) {
        window.scrollTo(500, 0);
        setLoadingSave(false);
        setErrorMsg(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
        console.error(err);
      }
    });
  };

  return (
    <div className="card">
      <div className="card-body" id="card">
        <h4
          className="mt-0 header-title"
          style={{ fontSize: "1.5rem", color: "black" }}
        >
          Aceitar convite
        </h4>
        <p>
          A organização {data?.company_name} efetuou um convite de
          relacionamento interno com a sua. Dessa forma, sua empresa atuará como{" "}
          <b>Destino</b> e a organização que fez o convite atuará como{" "}
          <b>Ponto de Coleta</b>
        </p>
        <Stepper activeStep={page - 1} alternativeLabel>
          <Step>
            <StepLabel>Confirmar Organização</StepLabel>
          </Step>
          <Step>
            <StepLabel>Criar Ponto de Coleta</StepLabel>
          </Step>
        </Stepper>
        {errorMsg && (
          <div
            className="alert alert-danger alert-dismissible fade show mt-3"
            role="alert"
          >
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={() => setErrorMsg(null)}
            ></button>

            <p style={{ marginBottom: 0, whiteSpace: "pre" }}>{errorMsg}</p>
          </div>
        )}
        {page === 1 && (
          <>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">Organização</label>
              <div className="input-group">
                <select className="form-control" disabled>
                  <option>{data?.company_name}</option>
                </select>
                <ConfirmOrganizationModal organization={data} />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                Nome Responsável <b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-sm-12">
                <input
                  type="text"
                  className="form-control"
                  onChange={handleInputChange}
                  value={form.responsible_name}
                  name="responsible_name"
                  placeholder="Fulano da Silva"
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-12 col-form-label">
                E-mail Responsável <b style={{ color: "red" }}>*</b>
              </label>
              <div className="col-sm-12">
                <input
                  type="email"
                  className="form-control"
                  onChange={handleInputChange}
                  value={form.responsible_email}
                  name="responsible_email"
                  placeholder="email@exemplo.com"
                />
              </div>
            </div>
          </>
        )}
        {page === 2 && (
          <>
            <h5
              className="mt-4 header-title"
              style={{ fontSize: "1.15rem", color: "black" }}
            >
              Vínculo de Resíduos/Rejeitos
            </h5>
            <p>
              Vincule os materiais que estão vindo da organização parceira com
              os seus de uma forma rápida e descomplicada.
            </p>
            <div className="table-rep-plugin">
              <div className="table-responsive mb-0">
                <table
                  className="table table-bordered"
                  style={{ tableLayout: "fixed" }}
                >
                  <colgroup>
                    <col style={{ width: "50%" }} />
                    <col style={{ width: "50%" }} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">Ponto de Coleta</th>
                      <th scope="col">Destino</th>
                    </tr>
                  </thead>
                  <tbody>
                    {internalRelationshipWasteRejects?.map((item, index) => (
                      <tr key={index}>
                        <td
                          className="align-middle"
                          style={{ wordWrap: "break-word" }}
                        >
                          {item.pickupPoint.name}
                        </td>
                        <td>
                          <select
                            className="form-select"
                            onChange={(e) =>
                              handleWasteRejectSelector(e.target.value, index)
                            }
                            value={item.destination.id}
                          >
                            <option value={""}>
                              Selecione um resíduo/rejeito
                            </option>
                            {wasteRejects.map((item, id) => (
                              <option value={item.id} key={id}>
                                {item?.nome}
                              </option>
                            ))}
                          </select>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <hr />
            <h5
              className="mt-4 header-title"
              style={{ fontSize: "1.15rem", color: "black" }}
            >
              Capacidade máxima
            </h5>
            <p>
              Preencha os dados de capacidade máxima do ponto de coleta
              parceiro.
            </p>
            <div className="form-group row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <label className="col-form-label">
                      Valor da capacidade máxima de peso{" "}
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <NumericFormat
                      type="text"
                      name="max_capacity_weight"
                      className="form-control"
                      valueIsNumericString
                      thousandsGroupStyle="thousand"
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      placeholder={"Valor"}
                      onValueChange={(values) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          max_capacity_weight: values.value,
                        }));
                      }}
                      value={form.max_capacity_weight}
                    />
                  </div>

                  <div className="col-md-6 col-12 ">
                    <label className="col-form-label">
                      Unidade da capacidade máxima de peso{" "}
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <select
                      className="form-control"
                      name="unity_weight_id"
                      onChange={handleInputChange}
                      value={form.unity_weight_id}
                    >
                      <option value={""}>Selecione uma unidade de peso</option>
                      {weightUnities.map((item, id) => (
                        <option key={id} value={item.id}>
                          {item.nome} - {item.sigla}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <label className="col-form-label">
                      Valor da capacidade máxima de volume{" "}
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <NumericFormat
                      type="text"
                      name="max_capacity_volume"
                      className="form-control"
                      valueIsNumericString
                      thousandsGroupStyle="thousand"
                      thousandSeparator="."
                      decimalScale={2}
                      decimalSeparator=","
                      placeholder={"Valor"}
                      onValueChange={(values) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          max_capacity_volume: values.value,
                        }));
                      }}
                      value={form.max_capacity_volume}
                    />
                  </div>

                  <div className="col-md-6 col-12 ">
                    <label className="col-form-label">
                      Unidade da capacidade máxima de volume{" "}
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <select
                      className="form-control"
                      name="unity_volume_id"
                      onChange={handleInputChange}
                      value={form.unity_volume_id}
                    >
                      <option value={""}>
                        Selecione uma unidade de volume
                      </option>
                      {volumeUnities.map((item, id) => (
                        <option key={id} value={item.id}>
                          {item.nome} - {item.sigla}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-group row mt-3">
              <div className=" col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-12">
                    <label className="col-sm-12 col-form-label">
                      Dias de funcionamento <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <div
                        className="d-flex justify-content-evenly align-items-center"
                        id="dayselector"
                      >
                        {Object.keys(expedient.weekdays).map((day, index) => (
                          <div
                            key={index}
                            onClick={() => toggleDay(day)}
                            className={
                              expedient.weekdays[day] ? "selectedDay" : ""
                            }
                          >
                            {day.substring(0, 1).toUpperCase()}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <label className="col-12 col-form-label">
                      Hora Início<b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        placeholder="Hora Início"
                        type="time"
                        name="openingTime"
                        onChange={(e) =>
                          setExpedient({
                            ...expedient,
                            openingTime: e.target.value,
                          })
                        }
                        value={expedient.openingTime}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <label className="col-12 col-form-label">
                      Hora Fim<b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <input
                        className="form-control"
                        placeholder="Hora Fim"
                        type="time"
                        name="closingTime"
                        onChange={(e) =>
                          setExpedient({
                            ...expedient,
                            closingTime: e.target.value,
                          })
                        }
                        value={expedient.closingTime}
                      />
                    </div>
                  </div>
                  <div className="d-grid gap-2 d-md-flex justify-content-md-between mt-3">
                    {expedient.errorMessage != "" && (
                      <b
                        className="mt-2"
                        style={{ color: "red", fontSize: "12px" }}
                      >
                        {expedient.errorMessage}
                      </b>
                    )}
                    <div></div>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleInsertExpedient}
                    >
                      Inserir
                    </button>
                  </div>
                  {expedient.openingHours.length > 0 && (
                    <div className="form-group row">
                      <label
                        className="col-sm-12 col-form-label"
                        style={{ color: "black" }}
                      >
                        Horários de Funcionamento:
                      </label>

                      <table
                        id="tech-companies-1"
                        className="table table-striped table-hover"
                      >
                        <thead>
                          <tr>
                            <th>Dias</th>
                            <th>Hora Início</th>
                            <th>Hora Fim</th>

                            <th data-priority="3">Excluir</th>
                          </tr>
                        </thead>
                        <tbody>
                          {expedient.openingHours.map((item, index) => (
                            <tr key={index}>
                              <td className="capitalized">
                                {item.day.map(
                                  (item2, id2) =>
                                    (id2 == 0 ? "" : " - ") + item2
                                )}
                              </td>
                              <td>{item.openingTime}</td>
                              <td>{item.closingTime}</td>
                              <td>
                                <button
                                  onClick={() => removeExpedientItem(index)}
                                  className="btn btn-danger btn-outline btn-circle me-1"
                                  style={{
                                    background: "white",
                                    border: "0px solid red",
                                  }}
                                >
                                  <FaTrash color="red" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {!loadingSave ? (
          <div className="row mt-4">
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
              style={{ marginBottom: "0.5rem" }}
            >
              {page === 1 ? (
                <Link to="/relacionamentos_internos">
                  <span className="btn btn-secondary btn-lg waves-effect waves-light">
                    <MdReply /> Voltar
                  </span>
                </Link>
              ) : (
                <span
                  className="btn btn-secondary btn-lg waves-effect waves-light"
                  onClick={() => setPage(1)}
                >
                  <MdReply /> Voltar
                </span>
              )}
            </div>
            <div
              className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
              style={{ marginBottom: "0.5rem" }}
            >
              <button
                type="button"
                onClick={() => acceptInvitation()}
                disabled={loadingSave}
                className="btn btn-success btn-lg waves-effect waves-light"
                style={{ backgorundColor: "#f2f2f2" }}
              >
                {page === 1 ? "Avançar" : "Aceitar Convite"}
              </button>
            </div>
          </div>
        ) : (
          <div className="row">
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <SweetAlert
          success
          title={"Sucesso"}
          onConfirm={() => {
            setShowAlert(false);
            history.push("/relacionamentos_internos");
          }}
          confirmBtnText="OK"
          confirmBtnBsStyle="success"
        >
          Convite aceito com sucesso!
        </SweetAlert>
      )}
    </div>
  );
};

export default ConvidarOrganizacao;
