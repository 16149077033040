import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { URL } from '../../../variables';
import { maskCep, maskCnpj, maskNumber, validateDecimals } from '../../../Auxiliar/Masks';

import { MdReply } from 'react-icons/md';
import { FaTrash, FaUser } from 'react-icons/fa';
import UsuarioModal from '../UsuarioModal/UsuarioModal';
import { NumericFormat } from 'react-number-format';
import SweetAlert from 'react-bootstrap-sweetalert';

class CriarEstoque extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/estoques',
            loading_screen: false,
            responsaveis: [],
            responsavel_id: '',

            cnpj: '',
            razao_social: '',
            nome_fantasia: '',

            numero: '',
            cep: '',
            bairro: '',
            cidade: '',
            estado: '',
            logradouro: '',
            complemento: '',

            loading_save: false,
            msgErro: '',
            pessoas_selecionadas: {},
            pessoas: {},
            pessoa: '',

            residuos_selecionadas: {},
            residuos: {},
            residuo: '',

            rejeitos_selecionadas: {},
            rejeitos: {},
            rejeito: '',

            capacidade_maxima_peso: '',
            capacidade_maxima_volume: '',
            unidade_peso_id: '',
            unidade_volume_id: '',
            unidades_peso: [],
            unidades_volume: [],
            refatch: false,
            sigor: false,
            unidade_sigor: '',

            show_warning: false
        };
    }

    get_data() {
        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_dados_estoque`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            },
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log('json', resp);
                    if (resp.message == 'Unauthenticated.') {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        // this.setState({redirect:true,path:'/'});
                        this.props.logout();
                        return;
                    }
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1) erro += errors[i] + '\n';
                            else erro += errors[i];
                        }
                        console.log(erro);
                        this.setState({ redirect: true });

                        //   setTimeout(()=>{
                        //     this.get_processo();
                        //   });
                    } else {
                        // this.props.mudarUser(resp.user);
                        let pessoas = {};
                        for (let i = 0; i < resp.autorizadas.length; i++) {
                            pessoas[resp.autorizadas[i].id] = resp.autorizadas[i];
                        }

                        let residuos = {};
                        for (let i = 0; i < resp.residuos.length; i++) {
                            residuos[resp.residuos[i].id] = resp.residuos[i];
                        }
                        let rejeitos = {};
                        for (let i = 0; i < resp.rejeitos.length; i++) {
                            rejeitos[resp.rejeitos[i].id] = resp.rejeitos[i];
                        }

                        //troca de autorizadas no backend para usuários com e sem permissão, e responsáveis estou passando os mesmos valores das "autorizadas"
                        console.log('pessoas', resp.autorizadas)
                        if (resp.empresa.seed_estoque) {
                            this.setState({
                                show_warning: true,
                                loading_screen: false,
                                empresa_data: resp.empresa,
                                responsaveis: resp.autorizadas,
                                rejeitos: rejeitos,
                                pessoas: resp.autorizadas,
                                residuos: residuos,
                                unidades_peso: resp.unidades_peso,
                                unidades_volume: resp.unidades_volume,
                            })
                        } else {
                            this.setState({
                                loading_screen: false,
                                responsaveis: resp.autorizadas,
                                rejeitos: rejeitos,
                                pessoas: resp.autorizadas,
                                residuos: residuos,
                                unidades_peso: resp.unidades_peso,
                                unidades_volume: resp.unidades_volume,
                            });
                        }
                    }
                } catch (err) {
                    this.setState({ redirect: true });
                    console.log(err);
                }
            })
            .catch((err) => {
                this.setState({ redirect: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    seed_estoque() {
        this.setState({
            cnpj: this.state.empresa_data.cnpj,
            razao_social: this.state.empresa_data.razao_social,
            nome_fantasia: this.state.empresa_data.nome_fantasia,
            numero: this.state.empresa_data.numero,
            cep: this.state.empresa_data.cep,
            bairro: this.state.empresa_data.bairro,
            cidade: this.state.empresa_data.cidade,
            estado: this.state.empresa_data.estado,
            logradouro: this.state.empresa_data.logradouro,
            complemento: this.state.empresa_data.complemento,
            show_warning: false,
        });
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        fetch(`${URL}api/store_estoques`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                responsavel_id: this.state.responsavel_id,

                cnpj: this.state.cnpj,
                razao_social: this.state.razao_social,
                nome_fantasia: this.state.nome_fantasia,
                responsavel_id: this.state.responsavel_id,
                unidade_peso_id: this.state.unidade_peso_id,
                unidade_volume_id: this.state.unidade_volume_id,
                capacidade_maxima_peso: this.state.capacidade_maxima_peso,
                capacidade_maxima_volume: this.state.capacidade_maxima_volume,

                numero: this.state.numero,

                cep: this.state.cep,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                logradouro: this.state.logradouro,
                complemento: this.state.complemento,
                pessoas: Object.keys(this.state.pessoas_selecionadas),
                residuos: Object.keys(this.state.residuos_selecionadas),
                rejeitos: Object.keys(this.state.rejeitos_selecionadas),

                sigor: this.state.sigor,
        num: this.state.unidade_sigor,
            }),
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    if (resp.errors != null || resp.error != null) {
                        let errors = Object.values(resp.errors);
                        let erro = '';
                        for (let i = 0; i < errors.length; i++) {
                            if (i != errors.length - 1) erro += errors[i] + '\n';
                            else erro += errors[i];
                        }
                        console.log(erro);
                        window.scrollTo(500, 0);
                        this.setState({ loading_save: false, msgErro: erro });
                    } else {
                        this.setState({ loading_save: false, redirect: true });
                    }
                } catch (err) {
                    console.log(err);
                    this.setState({
                        loading_save: false,
                        msgErro: 'Erro ao pegar resposta do servidor',
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading_save: false,
                    msgErro:
                        'Erro ao pegar resposta do servidor. Você está conectado a internet?',
                });
            });
    }

    buscar_endereco(cep) {
        this.setState({ loading_cep: true });
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {},
        })
            .then(async (responseLog) => {
                try {
                    let resp = await responseLog.json();
                    console.log(resp);
                    this.setState(
                        {
                            logradouro: resp.logradouro,
                            bairro: resp.bairro,
                            cidade: resp.localidade,
                            estado: resp.uf,
                            numero: '',
                            loading_cep: false,
                        },
                        () => {
                            if (this.state.logradouro == '') {
                                document.getElementById('rua').focus();
                            } else {
                                document.getElementById('numero').focus();
                            }
                        },
                    );

                    // await AsyncStorage.setItem('token', resp.access_token)
                    // await AsyncStorage.setItem('user', JSON.stringify(resp.user))
                } catch (err) {
                    console.log(err);
                    this.setState({
                        loading: false,
                        msgErro: 'Algo deu errado. Seu CEP está correto?',
                        loading_cep: false,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false,
                    msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?`,
                    loading_cep: false,
                });
            });
    }

    componentDidMount() {
        this.get_data();
    }

    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }

    render() {
        let anos = [];
        for (let i = parseInt(moment().format('YYYY')); i >= 1990; i--) {
            anos.push(i);
        }
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <h4
                                className="mt-0 header-title"
                                style={{ fontSize: '1.5rem', color: 'black' }}
                            >
                                Cadastrar estoque
                            </h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>
                                Cadastre os estoques do seu sistema
                            </p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && (
                                <div>
                                    {this.state.msgErro != '' && (
                                        <div>
                                            <div
                                                className="alert alert-danger alert-dismissible fade show mb-0"
                                                role="alert"
                                            >
                                                <button
                                                    type="button"
                                                    className="btn-close"
                                                    data-bs-dismiss="alert"
                                                    aria-label="Close"
                                                    onClick={() => this.setState({ msgErro: '' })}
                                                ></button>

                                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>
                                                    {this.state.msgErro}
                                                </p>
                                            </div>
                                            <br />
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">
                                                    CNPJ <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        className="form-control"
                                                        placeholder="CNPJ"
                                                        type="text"
                                                        onChange={(e) =>
                                                            this.setState({ cnpj: maskCnpj(e.target.value) })
                                                        }
                                                        value={this.state.cnpj}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">
                                                    Razão social <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Razão Social"
                                                        type="text"
                                                        onChange={(e) =>
                                                            this.setState({ razao_social: e.target.value })
                                                        }
                                                        value={this.state.razao_social}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">
                                                    Nome do Estoque <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        className="form-control"
                                                        placeholder="Nome Fantasia"
                                                        type="text"
                                                        onChange={(e) =>
                                                            this.setState({ nome_fantasia: e.target.value })
                                                        }
                                                        value={this.state.nome_fantasia}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">
                                                    Responsável <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <div className="input-group">
                                                        {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                        <select
                                                            value={this.state.responsavel_id}
                                                            className="form-control"
                                                            id="cameras"
                                                            onChange={(e) => {
                                                                let [resp_selecionado] =
                                                                    this.state.responsaveis.filter(
                                                                        (item) => item.id == e.target.value,
                                                                    );
                                                                let responsavel = [];
                                                                responsavel[e.target.value] = resp_selecionado;
                                                                this.setState({
                                                                    responsavel_id: e.target.value,
                                                                    pessoas_selecionadas: responsavel,
                                                                });
                                                            }}
                                                        >
                                                            <option value={''}>
                                                                Selecione um responsável
                                                            </option>

                                                            {this.state.responsaveis.map((item, id) => (
                                                                <option key={id} value={item.id}>
                                                                    {item.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        <UsuarioModal reload={(e) => this.get_data(e)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr />
                                            <div className="form-group row">
                                                <label className="col-sm-12 col-form-label">
                                                    CEP <b style={{ color: 'red' }}>*</b>
                                                </label>
                                                <div className="col-sm-12">
                                                    <input
                                                        className="form-control"
                                                        placeholder="CEP"
                                                        value={this.state.cep}
                                                        type="text"
                                                        onChange={(e) => {
                                                            this.setState({ cep: maskCep(e.target.value) });
                                                            let text = e.target.value.replace('-', '');
                                                            if (text.length == 8) {
                                                                this.buscar_endereco(text);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="form-group  row">
                                                <div className=" col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-8 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Logradouro <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    id="rua"
                                                                    placeholder="Logradouro do endereço"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            logradouro: e.target.value,
                                                                        })
                                                                    }
                                                                    value={this.state.logradouro}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Número <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    id="numero"
                                                                    placeholder="Número do endereço"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({ numero: e.target.value })
                                                                    }
                                                                    value={this.state.numero}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1 col-12">
                                                            <label
                                                                className="col-form-label col-sm-12"
                                                                htmlFor="select-all"
                                                            >
                                                                S/N
                                                            </label>
                                                            <div className="form-check form-switch col-sm-12 ">
                                                                <input
                                                                    className="form-check-input"
                                                                    checked={
                                                                        this.state.numero == 'S/N' ? true : false
                                                                    }
                                                                    type="checkbox"
                                                                    id={'select-all'}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            numero: e.target.checked ? 'S/N' : '',
                                                                        });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group  row">
                                                <div className=" col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-8 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Bairro <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Nome do bairro"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({ bairro: e.target.value })
                                                                    }
                                                                    value={this.state.bairro}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Complemento
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    placeholder="Complemento"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({
                                                                            complemento: e.target.value,
                                                                        })
                                                                    }
                                                                    value={this.state.complemento}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group  row">
                                                <div className=" col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-10 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Cidade <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    disabled
                                                                    placeholder="Nome da cidade"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({ cidade: e.target.value })
                                                                    }
                                                                    value={this.state.cidade}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 col-12">
                                                            <label className="col-sm-12 col-form-label">
                                                                Estado<b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <div className="col-sm-12">
                                                                <input
                                                                    className="form-control"
                                                                    disabled
                                                                    placeholder="UF"
                                                                    type="text"
                                                                    onChange={(e) =>
                                                                        this.setState({ estado: e.target.value })
                                                                    }
                                                                    value={this.state.estado}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-group row">
                                                <label
                                                    className="col-sm-12 col-form-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Pessoas autorizadas <b style={{ color: 'red' }}>*</b>
                                                </label>

                                                <div className="col-sm-12">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                    <div className="input-group">
                                                        <select
                                                            value={this.state.pessoa}
                                                            className="form-control"
                                                            id="pessoas"
                                                            onChange={(event) => {
                                                                let pessoas_selecionadas = {
                                                                    ...this.state.pessoas_selecionadas,
                                                                };
                                                                if (
                                                                    pessoas_selecionadas[event.target.value] ===
                                                                    undefined
                                                                ) {
                                                                    pessoas_selecionadas[event.target.value] =
                                                                        this.state.pessoas.filter(item => item.id === event.target.value)[0]
                                                                }
                                                                this.setState({
                                                                    pessoas_selecionadas: pessoas_selecionadas,
                                                                });
                                                            }}
                                                        >
                                                            <option value={''}>
                                                                Selecione uma ou mais pessoas autorizadas
                                                            </option>

                                                            {Object.values(this.state.pessoas)
                                                                .filter(
                                                                    (item1) =>
                                                                        item1.id !== this.state.responsavel_id,
                                                                )
                                                                .map((item, id) => (
                                                                    <option key={id} value={item.id}>
                                                                        {item.name}
                                                                    </option>
                                                                ))}
                                                        </select>

                                                        <UsuarioModal reload={(e) => this.get_data(e)} />
                                                    </div>
                                                </div>
                                            </div>

                                            {Object.values(this.state.pessoas_selecionadas).length !=
                                                0 && (
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">
                                                            Pessoas autorizadas selecionadas:
                                                        </label>

                                                        {Object.values(this.state.pessoas_selecionadas).map(
                                                            (item, id) => (
                                                                <div
                                                                    key={id}
                                                                    className="col-sm-6 col-12 col-md-4 col-lg-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        border: '1px solid gray',
                                                                        borderRadius: 10,
                                                                        marginLeft: 10,
                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    <span>{item.name}</span>
                                                                    {item.id == this.state.responsavel_id && (
                                                                        <span
                                                                            className=" btn-danger btn-outline btn-circle me-1"
                                                                            style={{ background: 'white' }}
                                                                        >
                                                                            <FaUser color="green" />
                                                                        </span>
                                                                    )}
                                                                    {item.id != this.state.responsavel_id && (
                                                                        <button
                                                                            onClick={() => {
                                                                                let pessoas_selecionadas = {
                                                                                    ...this.state.pessoas_selecionadas,
                                                                                };
                                                                                delete pessoas_selecionadas[item.id];
                                                                                this.setState({
                                                                                    pessoas_selecionadas:
                                                                                        pessoas_selecionadas,
                                                                                });
                                                                            }}
                                                                            className="btn btn-danger btn-outline btn-circle me-1"
                                                                            style={{
                                                                                background: 'white',
                                                                                border: '0px solid red',
                                                                            }}
                                                                        >
                                                                            <FaTrash color="red" />
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                )}

                                            <hr />
                                            <div className="form-group row">
                                                <label
                                                    className="col-sm-12 col-form-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Resíduos{' '}
                                                </label>

                                                <div className="col-sm-12">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                    <select
                                                        value={this.state.residuo}
                                                        className="form-control"
                                                        id="residuos"
                                                        onChange={(event) => {
                                                            let residuos_selecionadas = {
                                                                ...this.state.residuos_selecionadas,
                                                            };
                                                            if (
                                                                residuos_selecionadas[event.target.value] ==
                                                                undefined
                                                            ) {
                                                                residuos_selecionadas[event.target.value] =
                                                                    this.state.residuos[event.target.value];
                                                            }
                                                            this.setState({
                                                                residuos_selecionadas: residuos_selecionadas,
                                                            });
                                                        }}
                                                    >
                                                        <option value={''}>
                                                            Selecione um ou mais resíduos
                                                        </option>

                                                        {Object.values(this.state.residuos).map(
                                                            (item, id) => (
                                                                <option key={id} value={item.id}>
                                                                    {item.nome}
                                                                </option>
                                                            ),
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            {Object.values(this.state.residuos_selecionadas).length !=
                                                0 && (
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">
                                                            Resíduos selecionadas:
                                                        </label>

                                                        {Object.values(this.state.residuos_selecionadas).map(
                                                            (item, id) => (
                                                                <div
                                                                    key={id}
                                                                    className="col-sm-6 col-12 col-md-4 col-lg-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        border: '1px solid gray',
                                                                        borderRadius: 10,
                                                                        marginLeft: 10,
                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    <span>{item.nome}</span>
                                                                    <button
                                                                        onClick={() => {
                                                                            let residuos_selecionadas = {
                                                                                ...this.state.residuos_selecionadas,
                                                                            };
                                                                            delete residuos_selecionadas[item.id];
                                                                            this.setState({
                                                                                residuos_selecionadas:
                                                                                    residuos_selecionadas,
                                                                            });
                                                                        }}
                                                                        className="btn btn-danger btn-outline btn-circle me-1"
                                                                        style={{
                                                                            background: 'white',
                                                                            border: '0px solid red',
                                                                        }}
                                                                    >
                                                                        <FaTrash color="red" />
                                                                    </button>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                )}

                                            <div className="form-group row">
                                                <label
                                                    className="col-sm-12 col-form-label"
                                                    style={{ color: 'black' }}
                                                >
                                                    Rejeitos{' '}
                                                </label>

                                                <div className="col-sm-12">
                                                    {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'form-control','id'=>'faculdade']) }} */}
                                                    <select
                                                        value={this.state.rejeito}
                                                        className="form-control"
                                                        id="residuos"
                                                        onChange={(event) => {
                                                            let rejeitos_selecionadas = {
                                                                ...this.state.rejeitos_selecionadas,
                                                            };
                                                            if (
                                                                rejeitos_selecionadas[event.target.value] ==
                                                                undefined
                                                            ) {
                                                                rejeitos_selecionadas[event.target.value] =
                                                                    this.state.rejeitos[event.target.value];
                                                            }
                                                            this.setState({
                                                                rejeitos_selecionadas: rejeitos_selecionadas,
                                                            });
                                                        }}
                                                    >
                                                        <option value={''}>
                                                            Selecione um ou mais rejeitos
                                                        </option>

                                                        {Object.values(this.state.rejeitos).map(
                                                            (item, id) => (
                                                                <option key={id} value={item.id}>
                                                                    {item.nome}
                                                                </option>
                                                            ),
                                                        )}
                                                    </select>
                                                </div>
                                            </div>

                                            {Object.values(this.state.rejeitos_selecionadas).length !=
                                                0 && (
                                                    <div className="form-group row">
                                                        <label className="col-sm-12 col-form-label">
                                                            Rejeitos selecionadas:
                                                        </label>

                                                        {Object.values(this.state.rejeitos_selecionadas).map(
                                                            (item, id) => (
                                                                <div
                                                                    key={id}
                                                                    className="col-sm-6 col-12 col-md-4 col-lg-3"
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                        alignItems: 'center',
                                                                        border: '1px solid gray',
                                                                        borderRadius: 10,
                                                                        marginLeft: 10,
                                                                        marginTop: 10,
                                                                    }}
                                                                >
                                                                    <span>{item.nome}</span>
                                                                    <button
                                                                        onClick={() => {
                                                                            let rejeitos_selecionadas = {
                                                                                ...this.state.rejeitos_selecionadas,
                                                                            };
                                                                            delete rejeitos_selecionadas[item.id];
                                                                            this.setState({
                                                                                rejeitos_selecionadas:
                                                                                    rejeitos_selecionadas,
                                                                            });
                                                                        }}
                                                                        className="btn btn-danger btn-outline btn-circle me-1"
                                                                        style={{
                                                                            background: 'white',
                                                                            border: '0px solid red',
                                                                        }}
                                                                    >
                                                                        <FaTrash color="red" />
                                                                    </button>
                                                                </div>
                                                            ),
                                                        )}
                                                    </div>
                                                )}
                                            <hr />

                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-form-label">
                                                                Valor da capacidade máxima de peso{' '}
                                                                <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <NumericFormat
                                                                className="form-control"
                                                                placeholder={'Valor'}
                                                                type="text"
                                                                valueIsNumericString
                                                                thousandsGroupStyle='thousand'
                                                                thousandSeparator="."
                                                                decimalScale={2}
                                                                decimalSeparator=","
                                                                onValueChange={(values, sourceInfo) => {
                                                                    this.setState({
                                                                        capacidade_maxima_peso: values.value
                                                                    })
                                                                }}
                                                                value={this.state.capacidade_maxima_peso} />
                                                        </div>

                                                        <div className="col-md-6 col-12 ">
                                                            <label className="col-form-label">
                                                                Unidade da capacidade máxima de peso{' '}
                                                                <b style={{ color: 'red' }}>*</b>
                                                            </label>

                                                            {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                            <select
                                                                value={this.state.unidade_peso_id}
                                                                className="form-control"
                                                                id="cameras"
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        unidade_peso_id: e.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option value={''}>
                                                                    Selecione uma unidade de peso
                                                                </option>
                                                                {this.state.unidades_peso.map((item, id) => (
                                                                    <option key={id} value={item.id}>
                                                                        {item.nome} - {item.sigla}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-sm-12">
                                                    <div className="row">
                                                        <div className="col-md-6 col-12">
                                                            <label className="col-form-label">
                                                                Valor da capacidade máxima de volume{' '}
                                                                <b style={{ color: 'red' }}>*</b>
                                                            </label>
                                                            <NumericFormat
                                                                className="form-control"
                                                                placeholder={'Valor'}
                                                                type="text"
                                                                valueIsNumericString
                                                                thousandsGroupStyle='thousand'
                                                                thousandSeparator="."
                                                                decimalScale={2}
                                                                decimalSeparator=","
                                                                onValueChange={(values, sourceInfo) => {
                                                                    this.setState({
                                                                        capacidade_maxima_volume: values.value
                                                                    })
                                                                }}
                                                                value={this.state.capacidade_maxima_volume} />
                                                        </div>

                                                        <div className="col-md-6 col-12 ">
                                                            <label className="col-form-label">
                                                                Unidade da capacidade máxima de volume{' '}
                                                                <b style={{ color: 'red' }}>*</b>
                                                            </label>

                                                            {/* {{ Form::select('select_faculdade',$faculdades,null, ['autocomplete' => 'off','required' => true,'data-error' => 'Este campo é obrigatório.','class'=>'custom-select','id'=>'faculdade']) }} */}
                                                            <select
                                                                value={this.state.unidade_volume_id}
                                                                className="form-control"
                                                                id="cameras"
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        unidade_volume_id: e.target.value,
                                                                    });
                                                                }}
                                                            >
                                                                <option value={''}>
                                                                    Selecione uma unidade de volume
                                                                </option>
                                                                {this.state.unidades_volume.map((item, id) => (
                                                                    <option key={id} value={item.id}>
                                                                        {item.nome} - {item.sigla}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {this.props.permissions.sigor?.acesso && <div className="form-group row mt-5">
                      <div className="col-12">
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault2" checked={this.state.sigor} onChange={() => this.setState({ sigor: !this.state.sigor })} />
                          <label className="form-check-label" for="flexSwitchCheckDefault2">Possui registro no SIGOR</label>
                        </div>

                        {this.state.sigor &&
                          <div className="col-sm-12">
                            <label className="col-sm-12 col-form-label">Unidade<b style={{ color: 'red' }}>*</b></label>
                            <input className="form-control" placeholder="Número da unidade" type="text" onChange={(e) => this.setState({ unidade_sigor: e.target.value })} value={this.state.unidade_sigor} />
                          </div>}
                      </div>
                    </div>}

                                            {this.state.loading_save == false && (
                                                <div className="row mt-3">
                                                    <div
                                                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start"
                                                        style={{ marginBottom: '0.5rem' }}
                                                    >
                                                        <Link to="/estoques">
                                                            {' '}
                                                            <span className="btn btn-secondary btn-lg waves-effect waves-light">
                                                                <MdReply /> Voltar
                                                            </span>
                                                        </Link>
                                                    </div>
                                                    <div
                                                        className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end"
                                                        style={{ marginBottom: '0.5rem' }}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() => this.salvar()}
                                                            className="btn btn-success btn-lg waves-effect waves-light"
                                                            style={{ backgorundColor: '#f2f2f2' }}
                                                        >
                                                            Salvar estoque
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                     

                                        <SweetAlert
                                            warning
                                            title={'Atenção'}
                                            onConfirm={() => {
                                                this.seed_estoque()
                                            }}
                                            onCancel={() => {
                                                this.setState({ show_warning: false });
                                            }}
                                            show={this.state.show_warning}
                                            confirmBtnText="Sim"
                                            cancelBtnText="Cancelar"
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="danger"
                                            showCancel={true}
                                        >
                                            Deseja utilizar informações da empresa como estoque?
                                        </SweetAlert>

                                        {this.state.loading_save == true && (
                                            <div className="row">
                                                {' '}
                                                <div
                                                    className="col-12 d-flex justify-content-center align-items-center"
                                                    style={{ marginTop: '2rem' }}
                                                >
                                                    <div
                                                        className="spinner-border text-primary"
                                                        role="status"
                                                    ></div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    
                                </div>
                            )}

                            {this.state.loading_screen == true && (
                                <div className="row">
                                    {' '}
                                    <div
                                        className="col-12 d-flex justify-content-center align-items-center"
                                        style={{ marginTop: '2rem' }}
                                    >
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                        ></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapsStateToProps = (state) => ({
    token: state.AppReducer.token,
  permissions: state.AppReducer.permissoes,

});

export default connect(mapsStateToProps, { logout })(CriarEstoque);
