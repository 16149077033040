import React from 'react'
import { useHistory } from 'react-router-dom'
import { TbTruckDelivery } from 'react-icons/tb'
import { GoKebabHorizontal } from "react-icons/go"
import SweetAlert from 'react-bootstrap-sweetalert'
// import Pagination from '../../../Auxiliar/Pagination'
import { Pagination } from "@mui/material";
import { StatusColeta } from '../../../Configs/Status'
import { renderToast } from '../../../Auxiliar/Alerts'
import { DELETE, GET } from '../../../Auxiliar/Requests'
import OptionsButton from '../../../Auxiliar/OptionsButton'
import { Chip, CircularProgress, Tooltip } from '@mui/material'
import { MdDelete, MdModeEdit, MdVisibility, MdManageSearch } from 'react-icons/md'
import { GrCheckmark, GrDocumentDownload, GrDocumentPdf } from 'react-icons/gr'
import moment from 'moment'
import { useSelector } from 'react-redux'
 
import { URL } from '../../../variables';

const ListData = React.memo((props) => {
  const history = useHistory()
  let permissoes_org = useSelector(store => store.AppReducer.permissoes);

  const [data, setData] = React.useState([])
  const [label, setLabel] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [loadingDelete, setLoadingDelete] = React.useState(false)
  const [totalPages, setTotalPages] = React.useState(1)
  const [currentPage, setCurrentPage] = React.useState(1)

  const [idToDelete, setIdToDelete] = React.useState('')

 
   
  //-------------------------*-------------------------
  React.useEffect(() => {
    if (props.reload) {
      getData()
    }
  }, [props.search, props.reload])

  //-------------------------*-------------------------
  const getFilterOptions = () => {
    const result = {}

    for (const [key, { multiCheck, data }] of Object.entries(props.options)) {
      if (multiCheck) result[key] = data
        .map((dataItem, index) => dataItem.value ? index : null)
        .filter(index => index !== null)
    }

    return { ...result, endPoint: getEndPoint() }
  }

  const handleCheckboxChange = (id) => {
    if (props.selectedItems.includes(id)) {
      props.setSelectedItems(props.selectedItems.filter((item) => item !== id));
    } else {
      props.setSelectedItems([...props.selectedItems, id]);
    }
  };
  const token = props.token;
  //-------------------------*-------------------------
  const getData = async (page = 1) => {
    const { endPoint, statusType, statusMtr, statusInvoice } = getFilterOptions()
    const { search, dateFor, dateOf } = props

    setLoading(true)
    let response = await GET({
      url: `${endPoint}?page=${page}&search=${search}&dateFor=${dateFor}&dateOf=${dateOf}&statusType=${statusType}&statusMtr=${statusMtr}&statusInvoice=${statusInvoice}`
    })
    setLoading(false)

    if (!response || (!response.data && !response.solicitacoes)) {
      renderToast({ type: 'error', error: 'Houve um erro ao carregar os registros' })
      history.goBack()
      return
    }
    if(response.solicitacoes){
      response.data=response.solicitacoes;
    }

    setData(response.data.map(({ permissions, ...item }) => {
      return {
        ...item, permissions: {
          create: mapPermission(permissions.create, 'escrita', endPoint),
          update: mapPermission(permissions.update, 'escrita', endPoint),
          receive: mapPermission(permissions.receive, 'escrita', endPoint),
          view: mapPermission(permissions.view, 'leitura', endPoint),
          delete: mapPermission(permissions.delete, 'exclusao', endPoint),
          process_mtr: mapPermission(permissions.process_mtr, 'escrita', endPoint),
          process_crd: mapPermission(permissions.process_crd, 'escrita', endPoint)
        }
      }

    }))

    
    setTotalPages(response.pagination.last_page)
    setCurrentPage(response.pagination.current_page)
    setLabel(getEndPointLabel(endPoint))
  }
  console.log(currentPage)
  

  //-------------------------*-------------------------
  const getEndPoint = () => props.options.dataType.data[0].value ? 'solicitacoes_coleta' : (props.options.dataType.data[2].value?'destinacao':'despacho')
  const getEndPointLabel = (endPoint) => endPoint === 'despacho' ? 'despacho' : (endPoint=='solicitacoes_coleta'?'solicitação' :'destinação')
  const getUserPermission = (endPoint) => endPoint === 'despacho' ? 'realizar_despacho' :(endPoint=='solicitacoes_coleta'?'solicitacoes_coleta':'destinacao') 
  const mapPermission = (permission, action, endpoint) => permission && props.permissions[getUserPermission(endpoint)][action]

  //-------------------------*-------------------------
  // We need to refactor the delete function
  const handleDelete = async (url, id) => {
    setLoadingDelete(true)
    const response = await DELETE({ url: url + id })
    setLoadingDelete(false)

    if (!response || !response.status) {
      renderToast({ type: 'error', error: `Houve um erro ao deletar o registro de ${getEndPointLabel(getEndPoint())}` })
      return
    }

    setIdToDelete('')
    setData(data.filter(item => item.id !== id))
    renderToast({ type: 'success', error: `Registro de ${getEndPointLabel(getEndPoint())} deletado com sucesso!` })
  }

  //-------------------------*-------------------------
  // Receiving array of collection point or material names
  const renderArrayLabels = (array, key,statusRender=false) => (
    array.map(item => {
      const mapKey = (item.name || item) + key
      let label = item.name || item
      let fullLabel = null
      // Limiting the number of characters for each collection point or material on the list
      if (label.length > 20) {
        fullLabel = label
        label = label.slice(0, 20) + '... '
      }

      return (
        <div key={mapKey} className='d-flex'>
          {statusRender==false && <div className={'col-12'}>
            {fullLabel
              ?
              <Tooltip title={fullLabel} arrow placement='top' className='pointer'>
                <span>{label}</span>
              </Tooltip>
              :
              <span>{label}</span>}
          </div>}


          {statusRender && item.status &&
            <div className="col-12">
              <Chip
                label={item.status}
                color={StatusColeta.getSolicitationPallete(item.status)}
                size="small"
                className='ms-2 mb-2 w-100'
              />
            </div>}
        </div>
      )
    }))

  //-------------------------*-------------------------
  const processMTR = async (id) => {
    
    try {
      const response = await fetch(`${URL}api/sigor/post_salvar_Mtr_Em_Lote_Por_DespachoId`, {
        method: 'POST', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          despachoId: id
        })
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('MTR gerado com sucesso:', data);
        return { mtr: data.mtr, error: null };
      } else {
        const error = await response.text();
        console.error('Erro ao gerar MTR:', error);
        return { mtr: null, error };
      }
    } catch (error) {
      console.error('Erro ao gerar MTR:', error);
      return { mtr: null, error };
    }
    finally {
      window.location.reload();
    }
  };  

  const processCDF = async (id) => {
    try {
      const response = await fetch(`url_geracao_cdf`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${this.props.token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          mtrId: id,
        })
      });

      if (response.ok) {
        const data = await response.json();
        console.log('CDF gerado com sucesso:', data);
        return { cdf: data.cdf, error: null };
      } else {
        const error = await response.text();
        console.error('Erro ao gerar CDF:', error);
        return { cdf: null, error };
      }
    } catch (error) {
      console.error('Erro ao gerar CDF:', error);
      return { cdf: null, error };
    }
    finally {
      window.location.reload();
    }
  };
  //-------------------------*-------------------------
  const goToCreate = (id) => history.push(`/despacho/criar?collect=${id}`);
  const goToEdit = (id) => label === 'despacho' ? history.push(`/despacho/editar/interno/${id}`) : (label=='solicitação'? history.push(`/solicitar_coleta/editar/${id}`): history.push(`/destinacao/editar/externo/${id}`));
  const goToReceive = (id) => history.push(`/despachos_abertos/receber_coleta/${id}`);
  const goToView = (id) => label === 'despacho' ? history.push(`/coleta_despacho/despacho/view/${id}`) : (label=='solicitação'?history.push(`/solicitar_coleta/view/${id}`) :history.push(`/coleta_despacho/destinacao/view/${id}`));
  const goToRastreabilidade = (id) => label === 'despacho' ? history.push(`/rastreabilidade/despacho/${id}`) : (label=='solicitação'?history.push(`/rastreabilidade/solicitar_coleta/${id}`) :history.push(`/rastreabilidade/destinacao/${id}`));
  const handleDeleteItem = (id) => label === 'despacho' ? handleDelete(`delete_despacho_coleta/`, id) : (label=='solicitação'?handleDelete(`delete_solicitacao_coleta/`, id)  :handleDelete(`delete_destinacao_coleta/`, id));

  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <table id="tech-companies-1" className="table table-striped table-hover">
          <thead>
            <tr>
              {props.showCheckBox==true && label=='solicitação' && <th></th>}
              {props.allowSelect && <th>Selecione</th>}
              <th>Código</th>
              <th>Data de Criação</th>
              <th>{label=='despacho'? (`Status do ${label}`):`Status da ${label}`}</th>
              <th>Quantidade</th>
              <th>Info. Ponto Coleta</th>
              {label!='solicitação' ? <th>Status da solicitação</th>:<></>}

              <th>Info. Resíduos</th>

              <th>Documentos</th>
              <th data-priority="3">Ações</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              <>
                {data.length !== 0 ? (
                  data.map(item => (
                    <tr key={item.id}>
                      {props.showCheckBox==true && label=='solicitação' && <td>
                      {item.status=='Aguardando Despacho'  && <div  className='form-check my-1 ms-2'>
                        <input
                          className="form-check-input"
                          type={"checkbox"}
                          name="exampleRadios"
                          id={item.id}
                          value={item.id}
                          onChange={(e) => {
                            let options = {...props.selectCollects};
                            options[item.id] = e.target.checked? item:undefined;
                            props.setSelectCollects(options);
                          }}
                          checked={props.selectCollects[item.id]!=undefined}
                        />
                      </div>}
                      </td>}

                      {props.allowSelect && (
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => {
                              handleCheckboxChange(item.id);
                            }}
                            checked={props.selectedItems.includes(item.id)}
                          />
                        </td>
                      )}
                      <td>{item.codigo}</td>
                      <td>{moment(item.created_at).format('DD/MM/YYYY')}</td>

                      <td>{item.status}</td>
                      <td>{item.quantity}</td>
                      <td>{renderArrayLabels(item.collect_points, item.id)}</td>
                      {label!='solicitação' ? <td>{renderArrayLabels(item.collect_points, item.id,true)}</td>:<></>}

                      <td>{renderArrayLabels(item.materials, item.id)}</td>
                      <td>
                        <div className='d-flex flex-column'>
                        <span>MTR: {(item.documents?.mtr && item.documents.mtr_erros) ? <div className="d-inline-block bg-danger text-white px-2 rounded-pill" style={{fontSize: 'smaller', marginRight: '5px', marginLeft: '5px'}}>Com erro</div> : (item.documents?.mtr ?? 'Não Informado')}</span>
                          <span>NF: {item.documents.nf ?? 'Não Informado'}</span>
                          <span>CDF: {item.documents?.cdf?.error ? <div className="d-inline-block bg-danger text-white px-2 rounded-pill">Com erro</div> : (item.documents?.cdf ?? 'Não Informado')}</span>
                        </div>
                      </td>
                      <td>
                        {/* {console.log('teste gfunction: ', props.checkBoxFunction)} */}
                        <div className='d-flex'>
                          {props.checkBoxType &&
                            <input className="form-check-input" type="checkbox" onClick={() => props.checkBoxFunction(item)} />
                          }
                          <OptionsButton
                            Icon={<GoKebabHorizontal className='vertical-rotate' />}
                            items={[
                              // ...[label]
                              { label: `Criar ${label=='solicitação'?'despacho':label}`, icon: <TbTruckDelivery size={20} />, disabled: !item.permissions.create, action:()=> goToCreate(item.id) },
                              { label: `Alterar ${label}`, icon: <MdModeEdit />, disabled: !item.permissions.update, action: () => goToEdit(item.id) },
                              label === 'despacho' && { label: item.status=='Recebido'?'Finalizar despacho' :`Realizar recebimento`, icon: <GrCheckmark />, disabled: !item.permissions.receive, action: () => goToReceive(item.id) },
                              { label: `Visualizar detalhes`, icon: <MdVisibility />, disabled: !item.permissions.view, action: () => goToView(item.id) },
                              { label: `Rastreabilidade`, icon: <MdManageSearch />, disabled: !item.permissions.view || permissoes_org['blockchain'].acesso==false, action: () => goToRastreabilidade(item.id) },
                              { label: `Deletar ${label}`, icon: <MdDelete />, disabled: !item.permissions.delete, action: () => setIdToDelete(item.id) },
                              { label: 'Processar/Reprocessar MTR', icon: <GrDocumentPdf />, disabled: item.documents?.mtr && !item.documents.mtr_erros, action: () => processMTR(item.id) },
                              { label: `Processar/Reprocessar CDF`, icon: <GrDocumentDownload />, disabled: props.permissions.process_cdf && item.documents?.mtr !== null && item.documents?.cdf === null, action: () => processCDF(item.id) }
                            ]}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='text-center'>
                    <td colSpan={label=='solicitação'?8:7}>Sem registros encontrados</td>
                  </tr>
                )}
              </>
            ) : (
              <tr>
                <td colSpan={label=='solicitação'?10:9}>
                  <div className="col-12 d-flex justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status"></div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {totalPages && !loading &&
          <div className="col-12 d-flex justify-content-end">
            {/* <Pagination totalPages={totalPages} callback={getData} /> */}
            <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={(e,page)=>getData(page)}
                  // defaultPage={currentPage}
                  color="primary"
                />
          </div>}

        {idToDelete &&
          <SweetAlert
            warning
            title='Atenção'
            onConfirm={() => handleDeleteItem(idToDelete)}
            onCancel={() => setIdToDelete('')}
            show={idToDelete}
            showCancel
            confirmBtnText={loadingDelete ? <div style={{ minWidth: 166 }}><CircularProgress size={23} color='text' /></div> : 'Sim, desejo apagar'}
            cancelBtnText='Cancelar'
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
          >
            {`Ao deletar este registro de ${getEndPointLabel(getEndPoint())} ele não estará mais disponível no sistema`}
          </SweetAlert>}
      </div>
    </div>
  )
})


export default ListData
