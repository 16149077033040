//This screen is when we select a Internal Relationship on 'Criar Destinação Interna'.
//Here we create a ONLY VIEW 'Destinação Interna' and a 'Solicitação de Coleta' on our relationship enviroinment

import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { FaTrash } from "react-icons/fa";
import { GET } from "../../../../Auxiliar/Requests";
import { MdReply } from "react-icons/md";
import { NAME, URLadmin } from "../../../../variables";
import { ToastContainer, toast } from "react-toastify";

function DestinacaoInternaColeta() {
  //*utils
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const history = useHistory();

  //*form
  const [helpers, setHelpers] = useState({
    today: "",
    selectedMaterial: null,
    allWasteRejects: [],
    allowedWasteRejects: [],

    selectedMaterialWeight: "",
    selectedMaterialWeightKg: "",
    selectedMaterialWeightUnity: "",
    selectedMaterialVolume: "",
    selectedMaterialVolumeLt: "",
    selectedMaterialVolumeUnity: "",

    stocks: [],
  });
  const [form, setForm] = useState({
    destination: {},
    stock: null,
    pickupDate: "",
    material: "",
    selectedWasteRejects: [],
    observation: "",
  });

  useEffect(() => {
    //*get current date and set as minimum value on 'data da solicitação' input
    getData();
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    setHelpers((prevHelpers) => ({
      ...prevHelpers,
      today: `${year}-${month}-${day}`,
    }));
  }, []);

  const getData = async (page) => {
    const response = await GET({ url: `get_dados_destinacao_interna` });

    //find the destination that was selected on the other page...
    let destinationFound = response?.destinos.find(
      (item) => item.id === params.id
    );

    setForm((prevForm) => ({
      ...prevForm,
      destination: destinationFound,
    }));

    setHelpers((prevHelpers) => ({
      ...prevHelpers,
      stocks: response?.estoques,
      allWasteRejects: destinationFound?.residuos_rejeitos,
    }));

    setLoading(false);
  };

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleStockChange = ({ target }) => {
    const { value } = target;
    if (value !== "") {
      const selectedStock = helpers.stocks.find((item) => item.id === value);

      //when I select the stock, the waste rejects needs to be filtered based on what my Destination suppports
      const allowedWasteRejectsOnSelectedStock =
        helpers.allWasteRejects?.filter((obj1) => {
          return selectedStock.residuos_rejeitos?.some(
            (obj2) => obj2.id === obj1.id
          );
        });

      setHelpers((prevHelpers) => ({
        ...prevHelpers,
        allowedWasteRejects: allowedWasteRejectsOnSelectedStock,
        required: false,
        selectedMaterial: null,
        selectedMaterialWeight: "",
        selectedMaterialWeightKg: "",
        selectedMaterialWeightUnity: "",
        selectedMaterialVolume: "",
        selectedMaterialVolumeLt: "",
        selectedMaterialVolumeUnity: "",
      }));

      setForm((prevForm) => ({
        ...prevForm,
        stock: selectedStock,
        selectedWasteRejects: [],
      }));
    } else {
      //*handle empty stock
      setHelpers((prevHelpers) => ({
        ...prevHelpers,
        allowedWasteRejects: [],
        required: false,
        selectedMaterial: null,
        selectedMaterialWeight: "",
        selectedMaterialWeightKg: "",
        selectedMaterialWeightUnity: "",
        selectedMaterialVolume: "",
        selectedMaterialVolumeLt: "",
        selectedMaterialVolumeUnity: "",
      }));
      setForm((prevForm) => ({
        ...prevForm,
        stock: null,
        selectedWasteRejects: [],
      }));
    }
  };

  const handleOpenWasteRejectsProperties = (e) => {
    const { value } = e.target;

    const wasteRejectIsAlreadySelected = form.selectedWasteRejects.some(
      (wasteReject) => wasteReject.id === value
    );

    let findMaterial = null;
    //if the material wasn't inserted yet, this condition allows to be inserted
    if (!wasteRejectIsAlreadySelected) {
      findMaterial = helpers.allWasteRejects?.find((item) => item.id === value);
    }

    handleInputChange(e);

    setHelpers((prevHelpers) => ({
      ...prevHelpers,
      required: false,
      selectedMaterial: findMaterial,
      selectedMaterialWeight: "",
      selectedMaterialWeightKg: "",
      selectedMaterialWeightUnity: "",
      selectedMaterialVolume: "",
      selectedMaterialVolumeLt: "",
      selectedMaterialVolumeUnity: "",
    }));
  };

  function addWasteReject() {
    let { selectedMaterialWeight, selectedMaterialVolume } = helpers;
    //verify if weight or volume is empty... this is a required field
    if (selectedMaterialWeight === "" || selectedMaterialVolume === "") {
      setHelpers((prevHelpers) => ({ ...prevHelpers, required: true }));
      return;
    }

    let selectedWRArray = form.selectedWasteRejects;

    //double check if the material is already added
    const materialIsAdded = selectedWRArray.some(
      (wasteReject) => wasteReject.id === helpers.selectedMaterial.id
    );
    if (materialIsAdded) {
      setErrorMsg(
        "Material já inserido. Apague o registro existente e faça as devidas alterações."
      );
      window.scrollTo(500, 0);
      return;
    }

    //build a clean object to handle the materials on array
    let materialToAdd = {
      id: helpers.selectedMaterial.id,
      name: helpers.selectedMaterial.nome,
      weightUnity: helpers.selectedMaterialWeightUnity,
      volumeUnity: helpers.selectedMaterialVolumeUnity,

      weight: isNaN(helpers.selectedMaterialWeight)
        ? 0
        : helpers.selectedMaterialWeight,
      volume: isNaN(helpers.selectedMaterialVolume)
        ? 0
        : helpers.selectedMaterialVolume,
      weightKg: helpers.selectedMaterialWeightKg ?? 0,
      volumeLt: helpers.selectedMaterialVolumeLt ?? 0,

      type: helpers.selectedMaterial.tipo,
      equivalence: parseFloat(helpers.selectedMaterial.equivalencia),
      unity: {
        main: {
          id: helpers.selectedMaterial.unidade_principal.id,
          name: helpers.selectedMaterial.unidade_principal.nome,
          type: helpers.selectedMaterial.unidade_principal.tipo,
          equivalence: parseFloat(
            helpers.selectedMaterial.unidade_principal.equivalencia
          ),
        },
        secondary: {
          id: helpers.selectedMaterial.unidade_secundaria.id,
          name: helpers.selectedMaterial.unidade_secundaria.nome,
          type: helpers.selectedMaterial.unidade_secundaria.tipo,
          equivalence: parseFloat(
            helpers.selectedMaterial.unidade_secundaria.equivalencia
          ),
        },
      },
    };

    //add our clean object to 'selected materials' array
    selectedWRArray.push(materialToAdd);

    setForm((prevForm) => ({
      ...prevForm,
      selectedWasteRejects: selectedWRArray,
      material: "",
    }));
    setErrorMsg(null);

    //cleans everything, so we can add new properties
    setHelpers((prevHelpers) => ({
      ...prevHelpers,
      required: false,
      selectedMaterial: null,
      selectedMaterialWeight: "",
      selectedMaterialWeightKg: "",
      selectedMaterialWeightUnity: "",
      selectedMaterialVolume: "",
      selectedMaterialVolumeLt: "",
      selectedMaterialVolumeUnity: "",
    }));
  }

  function removeWasteReject({ id }) {
    //removing the waste reject clicked, based on it's ID
    const removingObjectBasedOnId = form.selectedWasteRejects.filter(
      (item) => item.id !== id
    );

    setForm((prevForm) => ({
      ...prevForm,
      selectedWasteRejects: removingObjectBasedOnId,
    }));
  }

  //* HANDLE SUBMIT */
  const createCollectDestination = () => {
    let formData = { ...form };
    formData.pickupPoint = { connection: NAME };

    //* validating waste rejects
    if (
      formData.pickupDate === "" ||
      formData.selectedWasteRejects.length < 1 ||
      !formData.stock
    ) {
      setErrorMsg("Preencha os campos corretamente.");
      window.scrollTo(500, 0);
      return;
    }

    toast.loading("Salvando coleta...", {
      toastId: "toastpromisse",
      hideProgressBar: false,
    });

    setLoadingSave(true);
    fetch(
      `${URLadmin}api/auth/admin/internal_relationship/destination_collect/store`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    ).then(async (responseLog) => {
      try {
        let response = await responseLog.json();

        if (response.response) {
          toast.update("toastpromisse", {
            render: "Solicitação Coleta criada com sucesso!",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
          history.push("/destinacao_interna");
          setLoadingSave(false);
          return;
        }

        if (response.message === "validation_error") {
          setErrorMsg("Preencha os campos corretamente");
          toast.update("toastpromisse", {
            render: "Erro, preencha os campos corretamente.",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        } else {
          setErrorMsg(response.message);
          toast.update("toastpromisse", {
            render: "Erro ao pegar resposta do servidor.",
            type: "error",
            isLoading: false,
            autoClose: 1000,
          });
        }

        setLoadingSave(false);
        window.scrollTo(500, 0);
      } catch (err) {
        window.scrollTo(500, 0);
        setLoadingSave(false);
        toast.update("toastpromisse", {
          render:
            "Erro ao pegar resposta do servidor. Você está conectado a internet?",
          type: "error",
          isLoading: false,
          autoClose: 1000,
        });
        setErrorMsg(
          "Erro ao pegar resposta do servidor. Você está conectado a internet?"
        );
        console.error(err);
      }
    });
  };

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body" id="card">
          <h4
            className="mt-0 header-title"
            style={{ fontSize: "1.5rem", color: "black" }}
          >
            Solicitação de coleta
          </h4>
          <p style={{ fontSize: "1rem", color: "black" }}>
            Cadastre uma nova solicitação de coleta para sua organização
            relacionada. Essa solicitação será inteiramente preenchida por algum
            usuário desse ambiente parceiro, mas você poderá acompanhar tudo no
            módulo "Gestão Interna", através da aba Realizar Destinação (definir
            onde estará).
          </p>
          <br />

          {errorMsg && (
            <div>
              <div
                className="alert alert-danger alert-dismissible fade show mb-0"
                role="alert"
              >
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setErrorMsg(null)}
                ></button>
                <p style={{ marginBottom: 0, whiteSpace: "pre" }}>{errorMsg}</p>
              </div>
              <br />
            </div>
          )}
          {!loading ? (
            <div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Destino <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="input-group">
                      <input
                        className="form-control"
                        placeholder="Destino selecionado"
                        type="text"
                        disabled
                        value={form.destination?.razao}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Data da solicitação <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12 d-flex align-items-center">
                      <input
                        className="form-control"
                        type="date"
                        name="pickupDate"
                        style={{ maxWidth: 168 }}
                        min={helpers.today}
                        value={form.pickupDate}
                        onChange={(e) => {
                          if (e.target.value < helpers.today) {
                            e.target.value = helpers.today;
                          }
                          handleInputChange(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Estoque <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12 d-flex align-items-center">
                      <select
                        className="form-control"
                        onChange={(e) => handleStockChange(e)}
                        value={form.stock?.id}
                        name="stock"
                      >
                        <option value={""}>Selecionar estoque</option>
                        {helpers.stocks?.map((item, index) => (
                          <option value={item.id} key={index}>
                            {item.razao_social}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Materiais <b style={{ color: "red" }}>*</b>
                    </label>
                    <div className="col-sm-12">
                      <select
                        name="material"
                        value={form.material}
                        className="form-control"
                        onChange={(e) => handleOpenWasteRejectsProperties(e)}
                      >
                        <option value={""}>
                          Selecione um ou mais materiais
                        </option>
                        {helpers.allowedWasteRejects?.map((item, id) => (
                          <option key={id} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {helpers.selectedMaterial && (
                  <>
                    <hr />
                    <div>
                      <label
                        className="col-form-label"
                        style={{ color: "red", textTransform: "uppercase" }}
                      >
                        {helpers.selectedMaterial.name}
                      </label>
                      {/* if main measurement is in weight */}
                      {helpers.selectedMaterial?.unidade_principal.tipo ===
                      "Peso" ? (
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">
                              Peso<b style={{ color: "red" }}>*</b>
                            </label>
                            <div className="input-group mb-3">
                              {/* <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                Disponível:
                                {maskNumberBr(
                                  form.stock?.capacidade_maxima_peso
                                )}
                                {" " + form.stock?.unidade_peso.sigla}
                              </span> */}
                              <NumericFormat
                                className="form-control "
                                id="peso"
                                valueIsNumericString
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                decimalScale={2}
                                decimalSeparator=","
                                placeholder="Peso do material"
                                type="text"
                                value={helpers.selectedMaterialWeight}
                                onValueChange={(values, e) => {
                                  const equivalence = parseInt(
                                    helpers.selectedMaterial.equivalencia
                                  );
                                  const { selectedMaterial } = helpers;

                                  setHelpers((prevHelpers) => ({
                                    ...prevHelpers,
                                    required: false,
                                    selectedMaterialWeight: values.floatValue,
                                    selectedMaterialVolume:
                                      equivalence * values.floatValue,

                                    selectedMaterialWeightUnity:
                                      selectedMaterial.unidade_principal.sigla,
                                    selectedMaterialVolumeUnity:
                                      selectedMaterial.unidade_secundaria.sigla,

                                    selectedMaterialWeightKg:
                                      values.floatValue *
                                      parseInt(
                                        selectedMaterial.unidade_principal
                                          .equivalencia
                                      ),
                                    selectedMaterialVolumeLt:
                                      equivalence *
                                      values.floatValue *
                                      parseInt(
                                        selectedMaterial.unidade_secundaria
                                          .equivalencia
                                      ),
                                  }));
                                }}
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                {
                                  helpers.selectedMaterial?.unidade_principal
                                    .sigla
                                }
                              </span>
                            </div>
                            {helpers.required && (
                              <b style={{ color: "red", fontSize: "12px" }}>
                                O campo Peso é obrigatório
                              </b>
                            )}
                          </div>
                          <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">
                              Volume<b style={{ color: "red" }}>*</b>
                            </label>
                            <div className="input-group mb-3">
                              {/* <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                Disponível:
                                {maskNumberBr(
                                  form.stock?.capacidade_maxima_volume
                                )}
                                {" " + form.stock?.unidade_volume.sigla}
                              </span> */}
                              <NumericFormat
                                className="form-control"
                                id="volume"
                                disabled
                                valueIsNumericString
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                decimalScale={2}
                                decimalSeparator=","
                                placeholder="Volume do material"
                                type="text"
                                value={helpers.selectedMaterialVolume}
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                {
                                  helpers.selectedMaterial?.unidade_secundaria
                                    .sigla
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        /* if main measurement is in volume */
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">
                              Volume
                              <b style={{ color: "red" }}>*</b>
                            </label>
                            <div className="input-group mb-3">
                              {/* <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                Disponível:
                                {maskNumberBr(
                                  form.stock?.capacidade_maxima_volume
                                )}
                                {" " + form.stock?.unidade_volume.sigla}
                              </span> */}
                              <NumericFormat
                                type="text"
                                className="form-control"
                                id="volume"
                                // disabled={this.state.clearStock}
                                valueIsNumericString
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                decimalScale={2}
                                decimalSeparator=","
                                placeholder={`Volume do material`}
                                onValueChange={(values, e) => {
                                  const equivalence = parseInt(
                                    helpers.selectedMaterial.equivalencia
                                  );
                                  const { selectedMaterial } = helpers;

                                  setHelpers((prevHelpers) => ({
                                    ...prevHelpers,
                                    required: false,
                                    selectedMaterialVolume: values.floatValue,
                                    selectedMaterialWeight:
                                      equivalence * values.floatValue,

                                    selectedMaterialVolumeUnity:
                                      selectedMaterial.unidade_principal.sigla,
                                    selectedMaterialWeightUnity:
                                      selectedMaterial.unidade_secundaria.sigla,

                                    selectedMaterialVolumeLt:
                                      values.floatValue *
                                      parseInt(
                                        selectedMaterial.unidade_principal
                                          .equivalencia
                                      ),
                                    selectedMaterialWeightKg:
                                      equivalence *
                                      values.floatValue *
                                      parseInt(
                                        selectedMaterial.unidade_secundaria
                                          .equivalencia
                                      ),
                                  }));
                                }}
                                value={helpers.selectedMaterialVolume}
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                {
                                  helpers.selectedMaterial?.unidade_principal
                                    .sigla
                                }
                              </span>
                            </div>
                            {helpers.required && (
                              <b style={{ color: "red", fontSize: "12px" }}>
                                O campo Volume é obrigatório
                              </b>
                            )}
                          </div>
                          <div className="col-md-6 col-12">
                            <label className="col-sm-12 col-form-label">
                              Peso<b style={{ color: "red" }}>*</b>
                            </label>
                            <div className="input-group mb-3">
                              {/* <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                Disponível:
                                {maskNumberBr(
                                  form.stock?.capacidade_maxima_peso
                                )}
                                {" " + form.stock?.unidade_peso.sigla}
                              </span> */}
                              <NumericFormat
                                type="text"
                                className="form-control"
                                id="peso"
                                disabled
                                valueIsNumericString
                                thousandsGroupStyle="thousand"
                                thousandSeparator="."
                                decimalScale={2}
                                decimalSeparator=","
                                placeholder="Peso do material"
                                value={helpers.selectedMaterialWeight}
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon3"
                              >
                                {
                                  helpers.selectedMaterial?.unidade_secundaria
                                    .sigla
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="d-flex align-items-center">
                        <div className="align-self-end ms-auto">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => addWasteReject()}
                          >
                            Inserir
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {form.selectedWasteRejects.length > 0 && (
                  <div className="container pt-3">
                    <label
                      className="col-form-label"
                      style={{ color: "black" }}
                    >
                      Materiais selecionados:
                    </label>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th>Material</th>
                              <th>Peso</th>
                              <th>Volume</th>
                              <th data-priority="3">Excluir</th>
                            </tr>
                          </thead>
                          <tbody>
                            {form.selectedWasteRejects.map((item, index) => (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>
                                  {item.weight} {item.weightUnity}
                                </td>
                                <td>
                                  {item.volume} {item.volumeUnity}
                                </td>
                                <td>
                                  <button
                                    onClick={() => {
                                      removeWasteReject(item);
                                    }}
                                    className="btn btn-danger btn-outline btn-circle m-r-5"
                                    style={{ background: "white" }}
                                  >
                                    <FaTrash color="red" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  <div className="form-group row">
                    <label className="col-sm-12 col-form-label">
                      Observação
                    </label>
                    <div className="col-sm-12 d-flex align-items-center">
                      <textarea
                        className="form-control"
                        placeholder="Descrição da coleta"
                        type="text"
                        onChange={handleInputChange}
                        value={form.observation}
                        name="observation"
                      ></textarea>
                    </div>
                  </div>
                </div>

                {!loadingSave ? (
                  <div className="pt-3 d-flex justify-content-between">
                    <Link to="/destinacao_interna">
                      <span className="btn btn-secondary btn-lg waves-effect waves-light">
                        <MdReply /> Voltar
                      </span>
                    </Link>
                    <button
                      type="button"
                      className="btn btn-success btn-lg waves-effect waves-light"
                      onClick={() => createCollectDestination()}
                    >
                      Criar Coleta
                    </button>
                  </div>
                ) : (
                  <div className="py-4 d-flex w-100 justify-content-center">
                    <div
                      className="spinner-border text-primary"
                      role="status"
                    ></div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="col-12 d-flex justify-content-center align-items-center"
              style={{ marginTop: "2rem" }}
            >
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        toastStyle={{ border: "1px solid gray", borderRadius: 4 }}
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </div>
  );
}

export default DestinacaoInternaColeta;
