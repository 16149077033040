
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../../actions/AppActions';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

// Registros
import moment from 'moment';
import { URL } from '../../../variables';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip';
import { MdDelete, MdModeEdit, MdSearch } from 'react-icons/md';
import { maskNumberBr } from '../../../Auxiliar/Masks';
import Filter from '../../../Auxiliar/Filter';

class RegistroEntrada extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            loading_search: false,
            RegistrosColeta: {},
            dados_contato: [],
            page: 1,
            total_pages: 1,
            msgErro: '',
            show_warning: false,
            show_warning_blocked: false,
            show_erro: false,
            registro_to_delete: '',
            search: '',
            Estoque: [],
            dateOf: '',
            dateFor: '',
            reload: false,
        };
    }

    componentDidMount() {
        this.get_Registros(this.state.page);
    }

    componentDidUpdate() {
        if (this.state.reload) {
            this.setState({ reload: false })
            this.get_Registros(this.state.page);
        }
    }

    get_Registros(page, search = '', busca = false) {
        if (busca == true) {
            if (this.state.loading_search == false) {
                this.setState({ loading_search: true });
            }
        }
        else if (this.state.loading_screen === false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/get_entrada_coleta?page=${page}&search=${search}&dateOf=${this.state.dateOf}&dateFor=${this.state.dateFor}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
                }
                else {
                    this.setState({
                        RegistrosColeta: resp.registros,
                        Estoque: resp.estoque,
                        loading_screen: false,
                        page: resp.pagination.current_page,
                        total_pages: resp.pagination.last_page,
                        loading_search: false
                    });
                }
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false); 
            });
    }


    delete_entrada_coleta(id) {

        fetch(`${URL}api/delete_entrada_coleta/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });
                }
                else {
                    this.setState({ show_warning: false });
                    this.get_Registros(this.state.page);
                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
            });

    }







    render() {
        console.log(this.state.total_pages);
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}



                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Registro de Entrada</h4>
                            <Filter setState={(e) => this.setState(e)} classComponent={true} />
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os registros de entradas do seu sistema</p>
                            <br />
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                <div className="row mb-2">
                                    <div className="col-12 col-md-6">
                                        <form className="app-search mt-1" style={{ width: '100%' }} onSubmit={(e) => {
                                            clearTimeout(this.pesquisarTimeout); this.setState({ page: 1 });
                                            this.get_Registros(1, this.state.search, true)
                                            e.preventDefault();
                                        }}>
                                            <div className="form-group mb-0" style={{ width: '100%', position: 'relative' }}>
                                                <input value={this.state.search} name="search" placeholder="Pesquisar pelo Ponto de coleta ou Material..." className="form-control" style={{ width: '100%', paddingRight: 40, borderRadius: 100 }} onChange={(e) => {
                                                    clearTimeout(this.pesquisarTimeout);
                                                    let search = e.target.value;
                                                    this.setState({ page: 1, search: search });
                                                    this.pesquisarTimeout = setTimeout(() => {
                                                        this.get_Registros(1, search, true)
                                                    }, 500);
                                                }} />
                                                <div style={{ width: 40, position: "absolute", right: 0, top: 0, bottom: 0, display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                                    <MdSearch />

                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                    {this.props.user.tipo_usuario.permissoes['registros_entrada_coleta'].escrita == true && <div className="col-12 col-md-6 d-flex justify-content-end">
                                        <Link to="/entrada_coleta/criar"><span
                                            className="btn btn-primary btn-lg waves-effect waves-light mt-1" >Registrar entrada</span></Link>
                                    </div>}
                                </div>
                                <div className="table-rep-plugin">
                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                        <table id="tech-companies-1" className="table table-striped table-hover" style={{ textAlign: 'center' }}>
                                            <thead>
                                                <tr>
                                                    <th>Ponto de coleta</th>
                                                    <th>Peso</th>
                                                    <th>Volume</th>
                                                    <th>Material</th>
                                                    <th>Data de entrada</th>
                                                    <th data-priority="3">Ações</th>
                                                </tr>
                                            </thead>
                                            {/* dados da lista ----------------------------------- */}
                                            <tbody>
                                                {this.state.loading_search == false && this.state.RegistrosColeta.map((item, id) => (
                                                    <tr key={id}>

                                                        <td>{item.pontos_coleta_id.nome_fantasia}</td>
                                                        <td>{item.residuo_rejeito_id.unidade_principal.tipo == 'Peso' ?
                                                            maskNumberBr(item.quantidade + '') + ' ' + item.residuo_rejeito_id.unidade_principal.sigla :
                                                            maskNumberBr(item.equivalencia + '') + ' ' + item.residuo_rejeito_id.unidade_secundaria.sigla}</td>
                                                        <td>{item.residuo_rejeito_id.unidade_principal.tipo == 'Volume' ?
                                                            maskNumberBr(item.quantidade + '') + ' ' + item.residuo_rejeito_id.unidade_principal.sigla :
                                                            maskNumberBr(item.equivalencia + '') + ' ' + item.residuo_rejeito_id.unidade_secundaria.sigla}</td>
                                                        <td>{item.residuo_rejeito_id.nome}</td>
                                                        <td>{item.data_entrada.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')}</td>
                                                        <td>
                                                            {this.props.user.tipo_usuario.permissoes['registros_entrada_coleta'].escrita == true && <Link to={'/entrada_coleta/editar/' + item.id}><span className="btn btn-info btn-outline btn-circle me-1 mb-1"
                                                                data-tip="Editar"
                                                            ><Tooltip /><MdModeEdit /></span></Link>}
                                                            {this.props.user.tipo_usuario.permissoes['registros_entrada_coleta'].exclusao == true && <button onClick={() => {
                                                                let registro = this.state.RegistrosColeta.filter(item2 => item2.id == item.id)
                                                                let estoque = this.state.Estoque.filter(est => est.ponto_coleta_id == registro[0].pontos_coleta_id.id && est.material_id == registro[0].residuo_rejeito_id.id);
                                                                let calc_registro;
                                                                if (registro[0].residuo_rejeito_id.unidade_principal.tipo == 'Peso') {
                                                                    calc_registro = parseFloat(estoque[0].peso_disponivel) - parseFloat(registro[0].quantidade);

                                                                    console.log('disponivel em estoque = ' + parseFloat(estoque[0].peso_disponivel));
                                                                    console.log('quantidade entrada = ' + parseFloat(registro[0].quantidade));
                                                                    console.log('calculo = ' + calc_registro);
                                                                    if (calc_registro >= 0) {
                                                                        this.setState({
                                                                            show_warning: true,
                                                                            registro_to_delete: item.id
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.setState({
                                                                            show_warning_blocked: true,
                                                                        });
                                                                    }
                                                                } else if (registro[0].residuo_rejeito_id.unidade_principal.tipo == 'Volume') {
                                                                    calc_registro = parseFloat(estoque[0].volume_disponivel) - parseFloat(registro[0].quantidade);

                                                                    console.log('disponivel em estoque = ' + parseFloat(estoque[0].volume_disponivel));
                                                                    console.log('quantidade entrada = ' + parseFloat(registro[0].quantidade));
                                                                    console.log('calculo = ' + calc_registro);
                                                                    if (calc_registro >= 0) {
                                                                        this.setState({
                                                                            show_warning: true,
                                                                            registro_to_delete: item.id
                                                                        });
                                                                    }
                                                                    else {
                                                                        this.setState({
                                                                            show_warning_blocked: true,
                                                                        });
                                                                    }
                                                                }


                                                            }}
                                                                className="destroy_it btn btn-danger btn-outline btn-circle me-1 mb-1" data-tip="Excluir"><Tooltip /><MdDelete />
                                                            </button>}
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        {this.state.loading_search == true && <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                            <div className="spinner-border text-primary" role="status"  >
                                            </div>
                                        </div></div>}
                                    </div>
                                </div>
                                {this.state.RegistrosColeta.length == 0 && <div className="row w-100">
                                    <div className="col-12">
                                        <p style={{ textAlign: 'center' }}>Nenhum Registro de Entrada cadastrado</p>

                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-end">
                                        <ReactPaginate
                                            previousLabel={'Anterior'}
                                            nextLabel={'Próxima'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={this.state.total_pages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={2}
                                            onPageChange={this.handlePageClick.bind(this)}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                            initialPage={this.state.page - 1}
                                        />
                                    </div>
                                </div>



                                {/* {this.state.andamento == true && <AvaliacaoAndamento get_processo={this.get_processo.bind(this)} {...this.props} />} */}

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}
                            <SweetAlert
                                warning
                                title={"Atenção"}
                                onConfirm={() => { this.delete_entrada_coleta(this.state.registro_to_delete); }}
                                onCancel={() => { this.setState({ show_warning: false }); }}
                                show={this.state.show_warning}
                                confirmBtnText='Sim, desejo apagar'
                                cancelBtnText='Cancelar'
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                showCancel={true}
                            >
                                Ao deletar este registro de entrada ele não estará mais disponível no sistema

                            </SweetAlert>

                            <SweetAlert
                                danger
                                title={"Atenção"}
                                onConfirm={() => { this.setState({ show_warning_blocked: false }); }}
                                show={this.state.show_warning_blocked}
                                confirmBtnText='Ok'
                                confirmBtnBsStyle="success"
                            >
                                O registro de entrada não pode ser deletado, pois está vinculado a uma solicitação de coleta
                            </SweetAlert>
                            <SweetAlert
                                danger
                                title={"Erro"}
                                onConfirm={() => {
                                    this.setState({ show_erro: false });
                                }}
                                show={this.state.show_erro}
                                confirmBtnText='OK'
                                confirmBtnBsStyle="primary"

                            >
                                {this.state.msgErro}

                            </SweetAlert>

                        </div>

                    </div>
                </div>
            </div>
        );
    }

    handlePageClick(page) {
        console.log(page.selected);
        if (page.selected + 1 != this.state.page) {
            this.setState({ page: page + 1 });
            this.get_Registros(page.selected + 1);

        }
    }
}
const mapsStateToProps = (state) => (
    {
        user: state.AppReducer.user,
        token: state.AppReducer.token,
    }
);

export default connect(mapsStateToProps, { logout })(RegistroEntrada);


