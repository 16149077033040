import React from 'react'
import { MdReply } from 'react-icons/md'
import Label from '../../../Auxiliar/Label'
import { GET } from '../../../Auxiliar/Requests'
import { StatusColeta } from '../../../Configs/Status'
import FinishDestinationModal from './FinishDestinationModal'
import StatusStepper from '../../../Auxiliar/StatusStepper'
import { maskDate, maskNumberBr, moneyMask, moneyMask2, numberLabelMask } from '../../../Auxiliar/Masks'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Chip } from '@mui/material'
import { URL } from '../../../variables'
import { NumericFormat } from 'react-number-format'
import { toast, ToastContainer } from 'react-toastify';
import ControlMtr from '../../../Home/ColetaTrasporte/MTR/ControlMtr';
import { useSelector } from 'react-redux';
const ViewDetails = () => {
  const history = useHistory()
  const { processType, id } = useParams()
  
  
  const [data, setData] = React.useState('')
  const [pesagens, setPesagens] = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const token = useSelector((store) => store.AppReducer.token);
  const [state, setState] = React.useState({
    active: [],
    canceled: [],
    codigoManifesto: '',
    pdf: '',
    pagination: '',
    pageNumber: 1,
    loading: true,
    loadingSearch: false,
    reload: false,
    search: '',
    showWarningCancel: false,
    loadingCancel: false,
    mtrToCancel: '',
    showWarningDelete: false,
    loadingDelete: false,
    mtrToDelete: '',
  })
  React.useEffect(() => {
    getData()
  }, [])

  //-------------------------*-------------------------
  const getData = async () => {
    let response = await GET({ url: `${processType}/view/${id}` })
    console.log(response)
    if (!response) {
      console.log('error')
      history.push('/acompanhar_etapas')
      return
    }
    // let pesagens = [];
    // response.data.collect_materials = response.data.materials.map((item, id) => {
    //   let material = response.data.materials.filter((item2, id2) => item2.residuo_rejeito_id == item.residuo_rejeito_id && item.solicitacao_id == item2.solicitacao_id);
    //   let obj = {}
    //   if (material.length > 0) {
    //     obj = { ...material[0] };
    //   }
    //   else {
    //     obj = { ...item };
    //   }
    //   obj.status = material.length == 0 ? 'Proveniente da solicitação' : (material[0].weight == item.weight ? 'Confirmado pelo motorista' : 'Alterado pelo motorista');
    //   let hasRegister = pesagens.filter(item2 => item2 === item.residuo_rejeito_id)[0]
    //   if (!hasRegister) pesagens = [...pesagens, item.residuo_rejeito_id]

    //   return obj;
    // })

    // let PesoTotal = response.data.collect_materials.reduce((total, item2) => total + parseFloat(item2.peso_kg), 0) || 0;
    // let VolumeTotal = response.data.collect_materials.reduce((total, item2) => total + parseFloat(item2.volume_lt), 0) || 0;
    // let ReceitaTotal = response.data.collect_materials.reduce((total, item2) => {
    //   if (item2.movimentacao === 'Receita') {
    //     return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
    //   } else {
    //     return total
    //   }
    // }, 0)

    // let DespesaTotal = response.data.collect_materials.reduce((total, item2) => {
    //   if (item2.movimentacao === 'Despesa') {
    //     return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
    //   } else {
    //     return total
    //   }
    // }, 0)

    // const mappedMaterials = response.data.collect_materials.map(item => {
    //   const material = item.residuo_rejeito

    //   return {
    //     ...item,
    //     "equivalencia_peso": material.unidade_principal.tipo === 'Peso'
    //       ? material.equivalencia
    //       : material.unidade_secundaria.equivalencia,
    //     "equivalencia_volume": material.unidade_principal.tipo !== 'Peso'
    //       ? material.equivalencia
    //       : material.unidade_secundaria.equivalencia,
    //     "peso_solicitado": item.peso,
    //     "volume_solicitado": item.volume,
    //     "peso_kg_solicitado": item.peso_kg,
    //     "volume_lt_solicitado": item.volume_lt,
    //   }
    // })
    // response.data.collect_materials = mappedMaterials


    setData(response.data)
    setLoading(false)
  }

  function download(blob, filename) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  
  const getMtr= async(cod) => {
    return fetch(`${URL}api/sigor/get_download_mtr/${cod}`, {
      method: 'GET',
      headers: {
        Accept: 'application/pdf', 
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/pdf',
      }
    })
      .then(async (response) => {
        response.blob().then(blob => download(blob,cod))
      })
  }

  return (
    <div className="card">
      <div className="card-body" id="card">
        {!loading && data && <>
          <h1 className="mb-4 header-title" style={{ fontSize: '1.5rem' }}>Detalhes da Solicitação</h1>
          <Label title='Status' text={data.status} />
          <div className="my-3" style={{ overflowY: 'scroll' }}>
            <StatusStepper statusIndex={StatusColeta.getKeyByValue(data.status)} />
          </div>
          <Label title={data.stock ? 'Estoque' : 'Destino'} text={data.stock ?? data.destiny} />
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados das solicitações em aberto</h2>
            {data.collect_requests.map(item => (
              <div className='mb-5' key={item.id}>
                <Label title='Solicitação de coleta' text={item.code} />
                <Label title='Ponto de coleta' text={item.collect_point} />
                <Label title='Responsável' text={item.responsible} />
                <Label title='Materiais selecionados' text='' />
                <table className="table table-striped table-hover"  >
                  <thead>
                    <tr>
                      <th>Material</th>
                      <th>Peso</th>
                      <th>Volume</th>
                      <th>Peso Solicitado</th>
                      <th>Volume Solicitado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.request_materials.map(material => (
                      <tr key={item.id + material.id}>
                        <td>{material.name}</td>
                        <td>{material.weight}</td>
                        <td>{material.volume}</td>
                        <td>{material.requested_weight}</td>
                        <td>{material.requested_volume}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do despacho</h2>
            <div className="d-flex align-items-center">
              <Label title='Situação' text='' spaced={false} />
              <Chip
                label={data.status}
                color={StatusColeta.getPallete(data.status)}
                size="small"
                className='ms-2'
              />
            </div>
            <Label title='Código' text={data.code} />
            <Label title='Data de criação' text={maskDate(data.created_at)} />
            <Label title='Agendado Para' text={data.scheduled_to ? maskDate(data.scheduled_to) : 'Não marcado'} />
            <Label title='Iniciado em' text={data.started_at ? maskDate(data.started_at) : 'Não iniciado'} />
            <Label title='Finalizado em' text={data.finished_at ? maskDate(data.finished_at) : 'Não finalizado'} />
            <Label title='Peso total' text={numberLabelMask(data.total_weight)} />
            <Label title='Volume total' text={numberLabelMask(data.total_volume)} />
            <Label title='Receita' text={moneyMask2(data.total_receipt)} />
            <Label title='Despesa' text={moneyMask2(data.total_expense)} />
            <Label title='Saldo' text={moneyMask2(data.balance)} />
            <Label title='Materiais selecionados' text='' />
            <table className="table table-striped table-hover"  >
              <thead>
                <tr>
                  <th>Material</th>
                  <th>Peso</th>
                  <th>Volume</th>
                  <th>Ponto Coleta</th>
                </tr>
              </thead>
              <tbody>
                {data.materials.map(item => (
                  <tr key={item.collect_point + item.id}>
                    <td>{item.name}</td>
                    <td>{numberLabelMask(item.weight) + ' ' + item.weightUnity}</td>
                    <td>{numberLabelMask(item.volume)+ ' ' + item.volumeUnity}</td>
                    <td>{item.collect_point}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data.collect_materials.length > 0 &&
            <div className="form-group row">
              <label className="col-sm-12 col-form-label" style={{ color: 'black' }}>Materiais recebidos:</label>

              <table id="tech-companies-1" className="table table-striped table-hover" >
                <thead>
                  <tr>
                    <th>Material</th>
                    <th>Peso</th>
                    <th>Volume</th>
                    <th>Ponto Coleta</th>
                    <th>Status</th>


                  </tr>
                </thead>
                <tbody>
                  {data.collect_materials.map((item) => (
                    <tr key={item.residuo_rejeito_id + '_' + item.solicitacao_id}>
                      <td style={{ verticalAlign: 'middle' }} >{item.nome}</td>
                      <td>
                        <div style={{ display: 'flex', alignItems: 'center' }}>

                          <NumericFormat className="form-control " id="peso"
                            valueIsNumericString
                            thousandsGroupStyle='thousand'
                            thousandSeparator="."
                            decimalScale={2}
                            decimalSeparator=","
                            placeholder="peso atendido"
                            type="text"
                            style={{ marginRight: 10, maxWidth: 100 }}
                            value={item.peso + ''}
                            onValueChange={(values, e) => {
                              //Defining variables
                              let pesagens = [...pesagens];
                              let despacho = { ...data };
                              let peso; let volume; let peso_kg; let volume_lt

                              //Resetting values ​​if the user deletes
                              if (values.value == '') {
                                despacho.collect_materials = despacho.collect_materials.map((item2, id2) => {
                                  if (item2.id == item.id) {
                                    item2.peso = '';
                                    item2.volume = '';
                                    item2.peso_kg = '';
                                    item2.volume_lt = '';
                                  }
                                  return item2;
                                })
                                return setData(despacho)
                                // return this.setState({ despacho })
                              }

                              //Conversion of values ​​if the type of waste or reject is "Peso"
                              if (item.residuo_rejeito.unidade_principal.tipo == 'Peso') {
                                peso = values.value;
                                volume = parseFloat(values.value) * parseFloat(item.residuo_rejeito.equivalencia)
                                peso_kg = parseFloat(values.value) * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)
                                volume_lt = parseFloat(values.value) * parseFloat(item.residuo_rejeito.equivalencia) * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)
                                //Conversion of values ​​if the type of waste or reject is "Volume"
                              } else {
                                peso = values.value;
                                volume = parseFloat(values.value) / parseFloat(item.residuo_rejeito.equivalencia)
                                peso_kg = parseFloat(values.value) * parseFloat(item.residuo_rejeito.unidade_secundaria.equivalencia)
                                volume_lt = (parseFloat(values.value) / parseFloat(item.residuo_rejeito.equivalencia)) * parseFloat(item.residuo_rejeito.unidade_principal.equivalencia)
                              }

                              //Checking if there is an object with the data of the respective residue or reject within the weighing array
                              let hasRegister = pesagens.filter(item2 => item2 === item.residuo_rejeito_id)[0]
                              if (!hasRegister) pesagens = [...pesagens, item.residuo_rejeito_id]

                              despacho.collect_materials = despacho.collect_materials.map((item2, id2) => {
                                if (item2.id == item.id) {

                                  item2.peso = peso;
                                  item2.volume = volume;
                                  item2.peso_kg = peso_kg;
                                  item2.volume_lt = volume_lt;
                                  item2.valor = peso * parseFloat(item2.residuo_rejeito.valor_saldo_financeiro);

                                  let material = despacho.collect_materials.filter((item3, id2) => item3.residuo_rejeito_id == item2.residuo_rejeito_id && item2.solicitacao_id == item3.solicitacao_id)[0];

                                  item2.status = parseFloat(item2.peso) != parseFloat(material.peso) ? 'Alterado pelo recebedor' : material.status;
                                }
                                return item2;
                              })

                              //Updating weight and volume states through values ​​in weighings
                              let PesoTotal = despacho.collect_materials.reduce((total, item2) => total + parseFloat(item2.peso_kg), 0) || 0;
                              let VolumeTotal = despacho.collect_materials.reduce((total, item2) => total + parseFloat(item2.volume_lt), 0) || 0;
                              let ReceitaTotal = despacho.collect_materials.reduce((total, item2) => {
                                if (item2.movimentacao === 'Receita') {
                                  return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
                                } else {
                                  return total
                                }
                              }, 0)

                              let DespesaTotal = despacho.collect_materials.reduce((total, item2) => {
                                if (item2.movimentacao === 'Despesa') {
                                  return total + parseFloat(item2.residuo_rejeito.valor_saldo_financeiro) * parseFloat(item2.peso)
                                } else {
                                  return total
                                }
                              }, 0)

                              despacho.total_weight = PesoTotal;
                              despacho.total_volume = VolumeTotal;
                              despacho.total_receipt = ReceitaTotal;
                              despacho.total_expense = DespesaTotal;
                              despacho.balance = ReceitaTotal - DespesaTotal;
                              setData(despacho)
                              setPesagens(pesagens)

                              // this.setState({ pesagens, despacho, PesoTotal, VolumeTotal, ReceitaTotal, DespesaTotal, Saldo: ReceitaTotal - DespesaTotal });
                            }} />

                          {item.tipo_unidade_principal == undefined ?
                            (item.residuo_rejeito.unidade_principal.tipo === 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla) :
                            (item.tipo_unidade_principal === 'Peso' ? item.unidade_principal : item.unidade_secundaria)}
                        </div>

                      </td>
                      {/* <td>{maskNumberBr(item.peso+'')} {item.tipo_unidade_principal == undefined ?
                                                                    item.residuo_rejeito.unidade_principal.tipo == 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                                                                    item.tipo_unidade_principal == 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td> */}
                      <td style={{ verticalAlign: 'middle' }}>{maskNumberBr(item.volume + '')} {item.tipo_unidade_principal == undefined ?
                        item.residuo_rejeito.unidade_principal.tipo !== 'Peso' ? item.residuo_rejeito.unidade_principal.sigla : item.residuo_rejeito.unidade_secundaria.sigla :
                        item.tipo_unidade_principal !== 'Peso' ? item.unidade_principal : item.unidade_secundaria}</td>
                      <td style={{ verticalAlign: 'middle' }}>{item.ponto_name}</td>
                      <td style={{ verticalAlign: 'middle' }}>{item.status}</td>


                    </tr>
                  ))}
                </tbody>
              </table>
            </div>}
          <hr className='my-5' />
          <div className="mt-4">
            <h2 className=" header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Dados do transporte</h2>
            <Label title='Transportadora' text={data.carrier} />
            <Label title='Veículos/Motoristas selecionados' text='' />
            <table className="table table-striped table-hover" >
              <thead>
                <tr>
                  <th>Veículo</th>
                  <th>Motorista</th>
                  <th>Capacidade Peso</th>
                  <th>Capacidade Volume</th>
                </tr>
              </thead>
              <tbody>
                {!data.vehicles.length ?
                  <tr>
                    <td colSpan="4" style={{ textAlign: 'center' }}>Nenhum Veículo/Motorista registrado</td>
                  </tr>
                  :
                  data.vehicles.map(item => (
                    <tr key={item.id}>
                      <td>{item.vehicle}</td>
                      <td>{item.driver}</td>
                      <td>{numberLabelMask(item.weight_capacity)}</td>
                      <td>{numberLabelMask(item.volume_capacity)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <Label title='Peso total Veículo(s)' text={numberLabelMask(data.total_weight_capacity)} />
            <Label title='Volume total Veículo(s)' text={numberLabelMask(data.total_volume_capacity)} />
            <Label title='Observações' text={data.description ?? 'Sem observações'} />
          </div>
          <hr className='my-5' />
          <h2 className="header-title" style={{ fontSize: '1.2rem', color: 'black' }}>Informações fiscais</h2>
          {data.documents.nf.key ?
            <>
              <div className="d-flex">
                <Label title='Chave nota' text={data.documents.nf.key} spaced={false} />
                <Label title='Número nota' text={data.documents.nf.number} className='ms-2' spaced={false} />
                <Label title='Série nota' text={data.documents.nf.serie} className='ms-2' spaced={false} />
              </div>
              {data.documents.nf.document &&
                <div className="row">
                  <iframe style={{ height: 1000 }} src={URL + 'storage/' + data.documents.nf.document} frameborder="0" />
                </div>}
            </> :
            <Label title='Nota físcal' text='Sem registros' />}

<div>
              {
                data.documents.mtr && data.documents.mtr_erros ? 
                  <div className="mt-3 text-danger">
                    MTR:{data.documents.mtr_erros}
                  </div>
                  :
                  (data.documents.mtr ?
                    <>
                      <span className='bold'>MTR:</span>{' '}
                      <button
                      onClick={()=>getMtr(data.documents.mtr )}

                      className="btn btn-success">Baixar MTR</button>
                      {/* {data.documents.mtr &&
                        <ControlMtr state={this.state} setState={(e) => this.setState(e)} type='active' getMtr={(e) => this.getMtr(e)} />} */}
                    </>
                : 
                <Label title='MTR' text={data.documents.mtr ?? 'Sem registros'} />)
              }
              </div>
              
             <div className="mt-2">
             {
                data.documents.cdf && data.documents.cdf.error ? 
                  <div className="mt-3 text-danger">
                    {data.documents.cdf.error}
                  </div>
                  :
                  (data.documents.cdf ?
                    <>
                      <span className='bold'>CDF:</span>{' '}
                      <button className="btn btn-success">Baixar CDF</button>
                    </>
                : <Label title='CDF' text={data.documents.cdf ?? 'Sem registros'} />)
              }
             </div>
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="btn btn-secondary btn-lg waves-effect waves-light"
              onClick={() => history.goBack()}>
              <MdReply /> Voltar
            </button>

            {(processType === 'destinacao' && !data.finished_at && data.status === 'Iniciado') && <FinishDestinationModal data={data} />}
          </div>
        </>}

        {loading &&
          <div className="col-12 d-flex justify-content-center align-items-center py-5">
            <div className="spinner-border text-primary" role="status"></div>
          </div>}
      </div>
    </div>
  )
}

export default ViewDetails