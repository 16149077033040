export class StatusColeta {
  // -------------------------Initializing values-------------------------
  static WAITING = 0;
  static DRAFT = 1;
  static SCHEDULED = 2;
  static PROGRESS = 3;
  static WITHDRAWAL = 4;
  static RECEIVED = 5;
  static FINISHED = 6;

  // -------------------------Defining business rules-------------------------
  static nameMapping = {
    [StatusColeta.WAITING]: 'Aguardando Despacho',
    [StatusColeta.DRAFT]: 'Rascunho',
    [StatusColeta.SCHEDULED]: 'Agendado',
    [StatusColeta.PROGRESS]: 'Iniciado',
    [StatusColeta.WITHDRAWAL]: 'Retirado',
    [StatusColeta.RECEIVED]: 'Recebido',
    [StatusColeta.FINISHED]: 'Finalizada',
  };

  static solicitationNameMapping = {
    [StatusColeta.WAITING]: 'Aguardando Despacho',
    [StatusColeta.PROGRESS]: 'Em Andamento',
    [StatusColeta.FINISHED]: 'Finalizada',
  };

  static colorMapping = {
    [StatusColeta.WAITING]: 'info',
    [StatusColeta.DRAFT]: 'info',
    [StatusColeta.SCHEDULED]: 'info',
    [StatusColeta.PROGRESS]: 'success',
    [StatusColeta.WITHDRAWAL]: 'success',
    [StatusColeta.RECEIVED]: 'success',
    [StatusColeta.FINISHED]: 'warning',
  };

  static hexColorMapping = {
    [StatusColeta.WAITING]: '#A6A6A6',
    [StatusColeta.DRAFT]: '#EF5350',
    [StatusColeta.SCHEDULED]: '#F6BE00',
    [StatusColeta.PROGRESS]: '#0288D1',
    [StatusColeta.WITHDRAWAL]: '#4CAF50',
    [StatusColeta.RECEIVED]: '#2E7D32',
    [StatusColeta.FINISHED]: '#FF9800',
  };

  // static hexColorMapping = {
  //   [StatusColeta.WAITING]: '#FE0100',
  //   [StatusColeta.DRAFT]: '#FF7D7D',
  //   [StatusColeta.SCHEDULED]: '#EDC02F',
  //   [StatusColeta.PROGRESS]: '#2676BB',
  //   [StatusColeta.WITHDRAWAL]: '#C5E2B6',
  //   [StatusColeta.RECEIVED]: '#00B452',
  //   [StatusColeta.FINISHED]: '#00B452',
  // };

  static statusMapping = [
    StatusColeta.WAITING,
    StatusColeta.DRAFT,
    StatusColeta.SCHEDULED,
    StatusColeta.PROGRESS,
    StatusColeta.WITHDRAWAL,
    StatusColeta.RECEIVED,
    StatusColeta.FINISHED
  ];

  static dispatchRouteMapping = {
    [StatusColeta.WAITING]: 'despacho/editar/interno',
    [StatusColeta.DRAFT]: 'despacho/editar/interno',
    [StatusColeta.SCHEDULED]: 'despacho/editar/interno',
    [StatusColeta.PROGRESS]: 'despachos_abertos/receber_coleta',
    [StatusColeta.WITHDRAWAL]: 'despachos_abertos/receber_coleta',
    [StatusColeta.RECEIVED]: 'despachos_abertos/receber_coleta',
    [StatusColeta.FINISHED]: 'despacho/view',
  };

  static destinationRouteMapping = {
    [StatusColeta.WAITING]: 'despacho/editar/externo',
    [StatusColeta.DRAFT]: 'despacho/editar/externo',
    [StatusColeta.SCHEDULED]: 'despacho/editar/externo',
    [StatusColeta.PROGRESS]: 'destinacao/view',
    [StatusColeta.WITHDRAWAL]: 'destinacao/view',
    [StatusColeta.RECEIVED]: 'destinacao/view',
    [StatusColeta.FINISHED]: 'destinacao/view',
  };

  // -------------------------Name mapping functions-------------------------
  static getKeyByValue = (value) => {
    return Number(Object.keys(StatusColeta.nameMapping).find(key => StatusColeta.nameMapping[key] === value));
  }

  static getStatusKeyByValue = (value) => {
    return Number(Object.keys(StatusColeta.solicitationNameMapping).find(key => StatusColeta.solicitationNameMapping[key] === value));
  }

  static getArrayItems = () => {
    return Object.values(StatusColeta.nameMapping);
  }

  static getArrayMap = (array) => {
    const result = array.map(item => StatusColeta.nameMapping[item] || null);

    return result.includes(null) ? null : result;
  }

  static getPallete = (value) => {
    return StatusColeta.colorMapping[this.getKeyByValue(value)] || null
  }

  static getSolicitationPallete = (value) => {
    return StatusColeta.colorMapping[this.getStatusKeyByValue(value)] || null
  }

  static getDispatchRoute = (value) => {
    return StatusColeta.dispatchRouteMapping[this.getKeyByValue(value)]
  }

  static getDestinationRoute = (value) => {
    return StatusColeta.destinationRouteMapping[this.getKeyByValue(value)]
  }
}

// Example Usage:
// const StatusColeta = new StatusColeta();
// console.log(StatusColeta.getKeyByValue('Rascunho') = return DRAFT ;
// console.log(StatusColeta.getPallete('Rascunho') = return info ;
// console.log(StatusColeta.getArrayMap([1, 2, 3])) = return ['Rascunho', 'Agendado', 'Iniciado'];

