import React from 'react'
import PropTypes from 'prop-types'
import { URL } from '../variables'

const FileInput = ({ callback, acceptedFiles, label, fileLabel, filePath }) => {
  const [file, setFile] = React.useState(filePath)
  const [isBase64File, setIsBase64File] = React.useState(false)

  const getFileBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setIsBase64File(true)
      setFile(reader.result)
      callback(reader.result.split(',')[1])
    }
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  return (
    <>
      <label className="input-group mt-3" htmlFor={'file-input' + label} style={{ cursor: 'pointer' }}>
        <div className="input-group-prepend">
          <span className="input-group-text">Procurar...</span>
        </div>
        <div className="form-control" >
          {label}
        </div>
      </label>
      <input
        id={'file-input' + label}
        className="d-none"
        type="file"
        accept={acceptedFiles}
        onChange={({ target }) => {
          getFileBase64(target.files[0]);
        }}
      />

      <div className="row">
        <label className="col-sm-12 col-form-label">
          {fileLabel}
        </label>
        {file ?
          <div className="row">
            <iframe style={{ height: 1000 }} src={isBase64File
              ? file
              : URL + 'storage/' + file} frameborder="0" />
          </div>
          : <p>Nenhum arquivo anexado</p>}
      </div>
    </>
  )
}

FileInput.propTypes = {
  callback: PropTypes.func,
  label: PropTypes.string,
  fileLabel: PropTypes.string,
  acceptedFiles: PropTypes.string,
  filePath: PropTypes.string,
};

FileInput.defaultProps = {
  callback: () => console.log('need callback'),
  label: 'Clique aqui para adicionar um arquivo',
  fileLabel: 'Arquivo: ',
  acceptedFiles: '*',
  filePath: '',
}

export default FileInput